import React from 'react';
import {connect} from 'react-redux';

import {Survey} from '../general/Styles';
import DiveDate from '../form/DiveDate';
import MyReefs from '../general/MyReefs';
import AllReefs from '../general/AllReefs';

const DateAndReef = () => {
	return (
		<Survey>
			<DiveDate />
			<div className="question">Select a reef to report on:</div>
			<MyReefs />
			<AllReefs />
		</Survey>
	);
};

export default connect()(DateAndReef);
