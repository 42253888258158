import React from 'react';
import {connect} from 'react-redux';
import {setUserState, setUserStateID} from '../../redux/main';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

const StatesAndIDs = (props) => {
	const {user, setUserState, setUserStateID} = props;

	return (
		<>
			<div className="question">In which State(s) do you dive?</div>
			<div className="checkblox statesAndIDs">
				<table>
					<tbody>
						{user.states.map((option, i) => {
							return (
								<tr key={i}>
									<td>
										<Checkbox
											checked={option.active}
											onChange={(ev) =>
												setUserState(ev.target.name, ev.target.checked)
											}
											name={option.state}
											id={option.state}
										/>
									</td>
									<td className="label">
										<label htmlFor={option.state}>{option.state}</label>
									</td>
									<td>
										{option.active && (
											<TextField
												fullWidth
												placeholder={option.id_name}
												value={option.id}
												onChange={(ev) =>
													setUserStateID(option.state, ev.target.value)
												}
											/>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		user: state.main.user,
	};
};

export default connect(mapStateToProps, {setUserState, setUserStateID})(
	StatesAndIDs,
);
