import React from 'react';
import {useHistory} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Privacy from './Privacy';
import Help from './Help';

export default function OptionsMenu() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showPrivacy, setShowPrivacy] = React.useState(false);
	const [showHelp, setShowHelp] = React.useState(false);
	const history = useHistory();

	const closeMenu = () => {
		setAnchorEl(null);
	};

	return (
		<div className="optionsMenu">
			<IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
				<MenuIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closeMenu}>
				<MenuItem
					onClick={() => {
						history.push({pathname: '/'});
						closeMenu();
					}}>
					Home
				</MenuItem>
				<MenuItem
					onClick={() => {
						history.push({pathname: '/settings'});
						closeMenu();
					}}>
					Settings
				</MenuItem>
				<MenuItem
					onClick={() => {
						setShowPrivacy(true);
						closeMenu();
					}}>
					Privacy Policy
				</MenuItem>
				<MenuItem
					onClick={() => {
						setShowHelp(true);
						closeMenu();
					}}>
					Help
				</MenuItem>
			</Menu>
			<Dialog open={showPrivacy} scroll={'paper'} fullScreen>
				<DialogContent>
					<Privacy />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowPrivacy(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={showHelp} scroll={'paper'} fullScreen>
				<DialogContent>
					<Help />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowHelp(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
