import {createMuiTheme} from '@material-ui/core/styles';

export default createMuiTheme({
	palette: {
		primary: {
			main: '#323589',
			opacity25: '#32358940',
		},
		secondary: {
			main: '#F17C35',
			opacity25: '#F17C3540',
		},
	},
});
