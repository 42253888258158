import React from 'react';
import {connect} from 'react-redux';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {InstallMsg} from '../general/Styles';
import imgIOSShare from '../../assets/ios-share.png';

const AppleInstallMessage = (props) => {
	const {setShowInstallMsg} = props;
	return (
		<InstallMsg>
			<div className="message">
				<div className="icon">
					<AddBoxIcon style={{fontSize: 50}} />
					<em>For the best performance and user experience please...</em>
				</div>
				<div>
					<b>Install DiverObs on your iPhone:</b>
				</div>
				<ul>
					<li>
						tap
						<img src={imgIOSShare} alt="Share Icon" width="24" height="24" />
						(below)
					</li>
					<li>
						and then <AddBoxIcon fontSize="small" /> <b>Add to Home Screen</b>
					</li>
				</ul>
				<div
					className="skip"
					onClick={() => {
						setShowInstallMsg(false);
					}}>
					skip
				</div>
			</div>
			<div className="arrow"></div>
		</InstallMsg>
	);
};

export default connect(null, null)(AppleInstallMessage);
