const factors = [
	{
		id: 'method',
		notes_id: 'method_notes',
		factor: 'Changing fishing method',
		eg: ['using a cage', 'T-piece', 'anchoring', 'other'],
	},
	{
		id: 'demand',
		notes_id: 'demand_notes',
		factor: 'Fishing to size/market',
		eg: ['low demand', 'low market price', 'other'],
	},
	{
		id: 'measure',
		notes_id: 'measure_notes',
		factor: 'Amount of measuring',
		eg: ['low demand', 'low market price', 'other'],
	},
	{
		id: 'equipment',
		notes_id: 'equipment_notes',
		factor: 'Equipment or mechanical issues',
		eg: ['boat', 'hookah', 'other'],
	},
	{
		id: 'ability',
		notes_id: 'ability_notes',
		factor: 'Diver or deckhand capacity',
		eg: ['diver', 'deckhand', 'illness', 'injury', 'training', 'other'],
	},
	{
		id: 'worked',
		notes_id: 'worked_notes',
		factor: 'Reef had been worked',
		eg: ['by other commercial divers'],
	},
	{
		id: 'conditions',
		notes_id: 'conditions_notes',
		factor: 'Environmental conditions',
		eg: ['swell', 'current', 'visibility'],
	},
	{
		id: 'habitat',
		notes_id: 'habitat_notes',
		factor: 'Habitat changes',
		eg: ['weed cover', 'kelp cover', 'sand movement', 'storm effects'],
	},
	{
		id: 'other',
		notes_id: 'other_notes',
		factor: 'Other factors',
		eg: ['size limits', 'rotational closures'],
	},
];

export default factors;