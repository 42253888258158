import React from 'react';
import {HelpWrapper} from '../general/Styles';
import PhoneIcon from '@material-ui/icons/Phone';
import MobileIcon from '@material-ui/icons/PhoneIphone';
const Help = (props) => {
	return (
		<HelpWrapper>
			<h3>DiverObs App – Help Menu</h3>
			<h4>About:</h4>

			<p>
				DiverObs will give divers a voice about the state of the fishery by
				reporting your observations. This is an industry-led initiative, and
				your responses will be sent to your industry organisation. Your DiverObs
				will be used together with other data sources to aid in the management
				of your fishery.
			</p>

			<p>It is important to complete a survey soon after every dive.</p>

			<p>
				The 2019-038 Abalone diver observation collection, analysis and
				reporting system for improved management decision making is supported by
				funding from the FRDC on behalf of the Australian Government.
			</p>

			<h4>For support contact your industry representative:</h4>

			<div className="contact">
				<div className="fishery">NSW</div>
				<div className="name">Dr. Duncan Worthington </div>
				<div className="mobile">
					<MobileIcon fontSize="small" />
					<span>0431 755 497</span>
				</div>
			</div>

			<div className="contact">
				<div className="fishery">Victoria Eastern Zone</div>
				<div className="name">Ms. Helen Jenkins</div>
				<div className="mobile">
					<MobileIcon fontSize="small" />
					<span>0498 074 978</span>
				</div>
			</div>

			<div className="contact">
				<div className="fishery">Victoria Central Zone</div>
				<div className="name">Mr. Josh Cahill</div>
				<div className="mobile">
					<MobileIcon fontSize="small" />
					<span>0424 096 355</span>
				</div>
			</div>

			<div className="contact">
				<div className="fishery">Victoria Western Zone</div>
				<div className="name">Mr. Harry Peters</div>
				<div className="mobile">
					<MobileIcon fontSize="small" />
					<span>0417 119 577</span>
				</div>
			</div>

			<div className="contact">
				<div className="fishery">South Australia Southern Zone</div>
				<div className="name">Mr. Arthur Martel</div>
				<div className="mobile">
					<PhoneIcon fontSize="small" />
					<span>08 8725 9995</span>
				</div>
			</div>

			<div className="contact">
				<div className="fishery">South Australia Central Zone</div>
				<div className="name">Mr. Michael Tokley</div>
				<div className="mobile">
					<MobileIcon fontSize="small" />
					<span>0418 841 114</span>
				</div>
			</div>

			<div className="contact">
				<div className="fishery">South Australia Western Zone</div>
				<div className="name">Dr. Nicole Hancox</div>
				<div className="mobile">
					<MobileIcon fontSize="small" />
					<span>0428 630 211</span>
				</div>
			</div>

			<div className="contact">
				<div className="fishery">Western Australia, Area 3</div>
				<div className="name">Mr. Angus Callendar</div>
				<div className="mobile">
					<MobileIcon fontSize="small" />
					<span>0417 913 467</span>
				</div>
			</div>

			<br />
			<h4>Question Guidance:</h4>
			<p>
				The survey asks you to compare your observations for this dive day
				against earlier time periods when you dived in the same general area.
				For example, if you observed more abalone on this dive day than you did
				during the comparison period your response to the question should be
				more.
			</p>
			<p>
				<b>Earlier this Season,</b> means earlier this fishing quota year.
			</p>
			<p>
				<b>Last Season,</b> means the previous fishing quota year.
			</p>
			<p>
				<b>2-4 Years Ago,</b> means 2-4 fishing quota years before the current
				fishing quota year.
			</p>
			<p>
				<b>Above legal size,</b> means above the legal size that applies to the
				reef area you dived.
			</p>
			<p>
				<b>Just under legal size,</b> means roughly 0-20 mm under the LML, or
				the abalone you expect to come into the fishery in the next 1-2 years.
			</p>
			<p>
				<b>Juvenile,</b> means abalone more than 20 mm below the LML.
			</p>
			<p>
				<b>This part of the reef,</b> means the same general area that you
				previously dived.
			</p>
			<p>
				<b>Overall Opinion,</b> means your opinion on the current state of the
				abalone stock level.
			</p>
			<p>
				<b>Expected Catch Rate</b> means the kg per hour you expected for the
				reef area given the time of year.
			</p>
		</HelpWrapper>
	);
};

export default Help;
