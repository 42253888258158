import React from 'react';
import {connect} from 'react-redux';
import {setSurveySpeciesValue} from '../../redux/main';
import factors from '../../redux/factors';

import Rating from '../form/AbaImpact';
import TextField from '@material-ui/core/TextField';

const Factors = (props) => {
	const {spec_id, species, setSurveySpeciesValue} = props;
	const spec = species[spec_id];

	return (
		<div className="Factors">
			{factors.map((factor, i) => {
				return (
					<div key={i} className="factor">
						<b>{factor.factor}</b>
						<Rating spec_id={spec_id} field={factor.id} />
						{spec[factor.id] > 1 && (
							<TextField
								fullWidth
								multiline
								variant="outlined"
								placeholder="Please give details..."
								id={factor.notes_id}
								value={spec[factor.notes_id]}
								onChange={(ev) =>
									setSurveySpeciesValue(
										factor.notes_id,
										ev.target.value,
										spec_id,
									)
								}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		species: state.main.survey.species,
	};
};

export default connect(mapStateToProps, {setSurveySpeciesValue})(Factors);
