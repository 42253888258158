import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputText = (props) => {
	const {ask, label, get, set, place, required} = props;
	const type = props.type || 'text';
	return (
		<>
			{ask && <div className="question">{ask}</div>}
			<TextField
				type={type}
				required={required}
				fullWidth
				label={label}
				value={get}
				placeholder={place}
				onChange={(ev) => set(ev.target.value)}
			/>
		</>
	);
};

export default InputText;
