import React from 'react';
import {connect} from 'react-redux';
import {Survey} from '../general/Styles';
import Scale from '../form/AbaScale';
import Rating from '../form/AbaRating';
import Factors from '../form/Factors';
import TextArea from '../form/TextArea';

const SpeciesSpecific = (props) => {
	const {spec_id, survey} = props;
	const {reef_code, reef_name, species, questions} = survey;

	const spec = species[spec_id];
	const spec_class = 'lip ' + spec.tag;
	const show = (field) => questions.includes(field);
	const any = (fields) => {
		return fields.filter((field) => questions.includes(field)).length > 0;
	};

	return (
		<Survey className="speciesSpecific">
			<h3>
				{reef_code} {reef_name}
			</h3>
			<h4 className={spec_class}>{spec.name} Population</h4>

			{show('earlier_above') && (
				<>
					<div className="question-head">
						Compared to <span className="nobreak">Earlier this Season</span>
					</div>
					<div className="specifics">
						How much <span className={spec_class}>{spec.name}</span>{' '}
						<b>above legal size</b> did you observe on <b>this part</b> of the
						reef?
					</div>
					<Scale spec_id={spec.id} field="earlier_above" period="earlier" />
				</>
			)}

			{any(['last_above', 'last_below', 'last_juvenile']) && (
				<div className="question-head">
					Compared to <span className="nobreak">Last Season</span>
				</div>
			)}

			{show('last_above') && (
				<>
					<div className="specifics">
						How much <span className={spec_class}>{spec.name}</span>{' '}
						<b>above legal size</b> did you observe on <b>this part</b> of the
						reef?
					</div>
					<Scale spec_id={spec.id} field="last_above" period="last" />
				</>
			)}
			{show('last_below') && (
				<>
					<div className="specifics">
						How much <span className={spec_class}>{spec.name}</span>{' '}
						<b>just under legal size</b> did you observe on <b>this part</b> of
						the reef?
					</div>
					<Scale spec_id={spec.id} field="last_below" period="last" />
				</>
			)}
			{show('last_juvenile') && (
				<>
					<div className="specifics">
						How much <b>juvenile</b>{' '}
						<span className={spec_class}>{spec.name}</span> did you observe on{' '}
						<b>this part</b> of the reef?
					</div>
					<Scale spec_id={spec.id} field="last_juvenile" period="last" />
				</>
			)}

			{any(['yearsago_above', 'yearsago_below', 'yearsago_juvenile']) && (
				<div className="question-head">
					Compared to <span className="nobreak">2-4 Years Ago</span>
				</div>
			)}
			{show('yearsago_above') && (
				<>
					<div className="specifics">
						How much <span className={spec_class}>{spec.name}</span>{' '}
						<b>above legal size</b> did you observe on <b>this part</b> of the
						reef?
					</div>
					<Scale spec_id={spec.id} field="yearsago_above" period="yearsago" />
				</>
			)}
			{show('yearsago_below') && (
				<>
					<div className="specifics">
						How much <span className={spec_class}>{spec.name}</span>{' '}
						<b>just under legal size</b> did you observe on <b>this part</b> of
						the reef?
					</div>
					<Scale spec_id={spec.id} field="yearsago_below" period="yearsago" />
				</>
			)}
			{show('yearsago_juvenile') && (
				<>
					<div className="specifics">
						How much <b>juvenile</b>{' '}
						<span className={spec_class}>{spec.name}</span> did you observe on{' '}
						<b>this part</b> of the reef?
					</div>
					<Scale
						spec_id={spec.id}
						field="yearsago_juvenile"
						period="yearsago"
					/>
				</>
			)}

			<div className="question-head short">Expected Catch Rate</div>
			<div className="specifics">
				How was your <span className={spec_class}>{spec.name}</span>{' '}
				<b>catch rate</b> today compared to you what you expected for{' '}
				<b>this part</b> of the reef?
			</div>
			<Rating spec_id={spec.id} field="catch" />

			<div className="question-head">Factors Impacting Catch Rate</div>
			<div className="question">
				Did any of these factors <b>impact</b> your catch rate of{' '}
				<span className={spec_class}>{spec.name}</span> on <b>this part</b> of
				the reef?
			</div>
			<Factors spec_id={spec.id} />

			<div className="question-head short">Overall Opinion</div>
			<div className="specifics">
				For <b>this part</b> of the reef, what is your <b>overall opinion</b> on
				the <span className={spec_class}>{spec.name}</span> stock level?
			</div>
			<Rating spec_id={spec.id} field="opinion" />

			<div className="question-head">Other Observations</div>
			<div className="question">
				Would you like to make any other observations or comments for{' '}
				<span className={spec_class}>{spec.name}</span> on <b>this part</b> of
				the reef?
			</div>
			<TextArea
				label="Habitat change"
				spec_id={spec.id}
				placeholder="e.g. weed cover, sand movement"
				id="comment_habitat"
				value={spec.comment_habitat}
			/>
			<TextArea
				label="Invasive or predator species"
				spec_id={spec.id}
				placeholder="e.g. urchins"
				id="comment_predators"
				value={spec.comment_predators}
			/>
			<TextArea
				label="Any other observations"
				spec_id={spec.id}
				id="comment_other"
				value={spec.comment_other}
			/>
		</Survey>
	);
};
const mapStateToProps = (state) => {
	return {
		survey: state.main.survey,
	};
};

export default connect(mapStateToProps)(SpeciesSpecific);
