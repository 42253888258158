import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Container from '@material-ui/core/Container';

import Calendar from '../general/Calendar';
import SurveyStarted from '../general/SurveyStarted';

const Home = (props) => {
	const {user_first, purpose_accepted} = props;

	if (!purpose_accepted) return <Redirect to={{pathname: '/purpose'}} />;
	if (!user_first) return <Redirect to={{pathname: '/settings'}} />;

	return (
		<>
			<Container>
				<div className="welcomeBack">
					Welcome back, <span>{user_first}</span>
				</div>
				<SurveyStarted />
				<Calendar />
				<div className="calendarText">
					<p>
						Please select a dive date from the calendar to report your
						observations on the part of the reef you fished on that day.
					</p>
					<p>It is important to complete a survey soon after every dive.</p>
					<p>
						An orange dot indicates that you have previously submitted a survey
						for this date. You can complete multiple surveys for a given date,
						if you dive different reefs on the same day.
					</p>
				</div>
			</Container>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		purpose_accepted: state.main.purpose_accepted,
		user_first: state.main.user.first,
	};
};

export default connect(mapStateToProps)(Home);
