import React from 'react';
import {Provider} from 'react-redux';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {ThemeProvider} from 'styled-components';
import Router from './components/pages/Router';
import Theme from './components/general/Theme.js';

import 'fontsource-roboto';
import './App.css';


const App = (props) => {
	return (
		<Provider store={props.store}>
			<MuiThemeProvider theme={Theme}>
				<ThemeProvider theme={Theme}>
					<Router />
				</ThemeProvider>
			</MuiThemeProvider>
		</Provider>
	);
};


export default App;
