import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {setStep} from '../../redux/main';
import {Redirect} from 'react-router-dom';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {StepperWrapper} from '../general/Styles';

import DateAndReef from './DateAndReef';
import PickSpecies from './PickSpecies';
import SpeciesSpecific from './SpeciesSpecific';
import FinalStep from './FinalStep';
const extraPages = 1;

const getStepContent = (step, maxSteps, species) => {
	if (maxSteps - step === extraPages) return <FinalStep />;
	switch (step) {
		case 0:
			return <DateAndReef />;
		case 1:
			return <PickSpecies />;
		case 2:
		case 3:
		case 4:
			const active = species.filter((spec) => spec.active);
			const spec_id = active[step - 2].id;
			return <SpeciesSpecific spec_id={spec_id} />;
		default:
			return null;
	}
};

const Stepper = (props) => {
	const {step, maxSteps, stepDone, survey, setStep} = props;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [step]);

	if (!survey) return <Redirect to={{pathname: '/'}} />;

	return (
		<StepperWrapper>
			{getStepContent(step, maxSteps, survey.species)}
			<MobileStepper
				steps={maxSteps}
				position="bottom"
				variant="dots"
				activeStep={step}
				nextButton={
					
					<Button
						size="small"
						onClick={() => {
							setStep(+1);
						}}
						disabled={!stepDone}>
						{maxSteps - step !== extraPages && (
							<>
								Next
								<KeyboardArrowRight />
							</>
						)}
					</Button>
				}
				backButton={
					<Button
						size="small"
						onClick={() => setStep(-1)}
						disabled={step === 0}>
						<KeyboardArrowLeft />
						Back
					</Button>
				}
			/>
		</StepperWrapper>
	);
};
const mapStateToProps = (state) => {
	return {
		step: state.main.step,
		maxSteps: state.main.maxSteps,
		stepDone: state.main.stepDone,
		survey: state.main.survey,
	};
};

export default connect(mapStateToProps, {setStep})(Stepper);
