import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {startSurvey} from '../../redux/main';

import moment from 'moment';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const month = (date, events, startSurvey, history) => {
	const first = moment(date, 'YYYYMM').startOf('month');
	let day = moment(first).subtract(moment(first).format('d'), 'days');
	const last = moment(first).endOf('month');

	const cell = (day, delta) => {
		const date = moment(day).add(delta, 'days');
		const sql = date.format('YYYY-MM-DD');
		const lastmonth = date < first ? 'lastmonth' : '';
		const nextmonth = date > last ? 'nextmonth' : '';
		const today = moment().isSame(date, 'day') ? 'today' : '';
		const future = date > moment() ? 'future' : '';
		return (
			<td
				className={`${lastmonth} ${nextmonth} ${today} ${future}`}
				key={sql}
				onClick={() => {
					if (date <= moment()) {
						startSurvey(date, history);
					}
				}}>
				{events.includes(sql) ? (
					<span className="done">{date.format('D')}</span>
				) : (
					date.format('D')
				)}
			</td>
		);
	};
	const week = (day) => (
		<tr key={day.format('YYYYMMDD')}>
			{[0, 1, 2, 3, 4, 5, 6].map((i) => cell(day, i))}
		</tr>
	);
	const month = [];
	while (day < last) {
		month.push(week(day, first, last));
		day = day.add(7, 'days');
	}
	return month;
};
const Calendar = (props) => {
	const {past_surveys, startSurvey} = props;
	const history = useHistory();
	const [date, setDate] = React.useState(
		moment().startOf('month').format('YYYYMM'),
	);

	return (
		<div className="Calendar">
			<table>
				<thead>
					<tr>
						<td colSpan="7">
							<Button
								onClick={() =>
									setDate(moment(date).subtract(1, 'month').format('YYYYMM'))
								}>
								<KeyboardArrowLeft />
							</Button>
							<h3>{moment(date, 'YYYYMM').format('MMMM YYYY')}</h3>
							<Button
								onClick={() =>
									setDate(moment(date).add(1, 'month').format('YYYYMM'))
								}>
								<KeyboardArrowRight />
							</Button>
						</td>
					</tr>
					<tr>
						<th>Sun</th>
						<th>Mon</th>
						<th>Tue</th>
						<th>Wed</th>
						<th>Thu</th>
						<th>Fri</th>
						<th>Sat</th>
					</tr>
				</thead>
				<tbody>{month(date, past_surveys, startSurvey, history)}</tbody>
			</table>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		past_surveys: state.main.past_surveys,
	};
};

export default connect(mapStateToProps, {startSurvey})(Calendar);
