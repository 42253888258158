import React, {useState, useMemo} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {setUserValue, saveSettings} from '../../redux/main';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import {Survey} from '../general/Styles';
import InputText from '../form/InputText';
import StatesAndIDs from '../form/StatesAndIDs';
import PickFisheries from '../form/PickFisheries';

const Settings = (props) => {
	const {purpose_accepted, user, device_id, setUserValue, saveSettings} = props;
	const {first, family, age, commenced, submitted} = user;
	const history = useHistory();
	const [saved, setSaved] = useState(false);

	const warnings = useMemo(() => {
		const fields = [
			{
				field: 'first',
				warning: 'Please enter your first name',
				show: false,
			},
			{
				field: 'family',
				warning: 'Please enter your family name (surname)',
				show: false,
			},
			{
				field: 'states',
				warning: 'Please select one or more state(s) where you dive',
				show: false,
			},
			{
				field: 'license',
				warning: 'Please enter your Diver Number for each state where you dive',
				show: false,
			},
			{
				field: 'fishery',
				warning: 'Please select at least one fishery',
				show: false,
			},
		];

		fields.forEach((item) => {
			switch (item.field) {
				case 'states':
					const my_states = user.states.filter((state) => state.active);
					item.show = my_states.length === 0;
					break;
				case 'license':
					const my_licenses = user.states
						.filter((state) => state.active)
						.filter((state) => state.id === '');
					item.show = my_licenses.length > 0;
					break;
				case 'fishery':
					const any_states = user.states.filter((state) => state.active);
					if (any_states.length === 0) return;
					item.show = user.my_zones.length === 0;
					break;
				default:
					item.show = !user[item.field];
					break;
			}
		});
		return fields;
	}, [user]);

	const save = () => {
		setSaved(true);
		const active_warnings = warnings.filter((warning) => warning.show);

		if (active_warnings.length === 0) {
			saveSettings(history, user, device_id);
		}
	};

	if (!purpose_accepted) return <Redirect to={{pathname: '/purpose'}} />;

	return (
		<Survey>
			{submitted ? (
				<h2>Settings</h2>
			) : (
				<h3>Please tell us a bit about yourself...</h3>
			)}
			<InputText
				label="First Name"
				get={first}
				set={(value) => setUserValue('first', value)}
			/>
			<InputText
				label="Family Name"
				get={family}
				set={(value) => setUserValue('family', value)}
			/>
			<InputText
				type="number"
				label="Age"
				get={age}
				set={(value) => setUserValue('age', value)}
			/>
			<InputText
				type="number"
				ask="What year did you commence as an abalone diver?"
				get={commenced}
				place="YYYY"
				set={(value) => setUserValue('commenced', value)}
			/>
			<StatesAndIDs />
			<PickFisheries />
			{saved &&
				warnings.map((item, i) => {
					if (!item.show) return null;
					return (
						<Alert severity="error" key={i}>
							{item.warning}...
						</Alert>
					);
				})}
			<Button
				variant="contained"
				color="primary"
				className="centered spacebefore"
				onClick={save}>
				Save
			</Button>
			<div
				style={{
					color: '#aaa',
					position: 'fixed',
					bottom: 0,
					left: '5px',
					background: 'white',
					width: '100%',
				}}>
				{process.env.REACT_APP_VERSION}
			</div>
		</Survey>
	);
};
const mapStateToProps = (state) => {
	return {
		purpose_accepted: state.main.purpose_accepted,
		first: state.main.user.first,
		family: state.main.user.family,
		age: state.main.user.age,
		commenced: state.main.user.commenced,
		submitted: state.main.user.submitted,
		user: state.main.user,
		device_id: state.main.device_id,
	};
};

export default connect(mapStateToProps, {
	setUserValue,
	saveSettings,
})(Settings);
