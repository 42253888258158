import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {acceptPurpose} from '../../redux/main';
import Button from '@material-ui/core/Button';
import {Survey} from '../general/Styles';
import AppleInstallMessage from '../general/AppleInstallMessage';
import imgLogo512 from '../../assets/logo512.png';

const Purpose = (props) => {
	const {acceptPurpose} = props;
	const history = useHistory();
	const [showInstallMsg, setShowInstallMsg] = useState(null);

	useEffect(() => {
		const isIos = () => {
			const userAgent = window.navigator.userAgent.toLowerCase();
			return /iphone|ipad|ipod/.test(userAgent);
		};
		const isInStandaloneMode = () => {
			return 'standalone' in window.navigator && window.navigator.standalone;
		};
		setShowInstallMsg(isIos() && !isInStandaloneMode());
	}, [setShowInstallMsg]);

	if (showInstallMsg === null) {
		return null;
	}

	return (
		<Survey>
			<div className="appTitle">
				Diver<b>Obs</b>
			</div>
			<img
				src={imgLogo512}
				alt="Cartoon Diver Logo"
				className="fullWidthLogo"
			/>

			{showInstallMsg ? (
				<AppleInstallMessage setShowInstallMsg={setShowInstallMsg} />
			) : (
				<>
					<h3>Welcome AbDiver!</h3>
					<p className="beforeNotice">
						DiverObs will give divers a voice about the state of the fishery by
						reporting your observations.
					</p>
					<p className="beforeNotice">
						This is an industry-led initiative, and your responses will be sent
						and held privately by your industry organisation.
					</p>
					<p className="beforeNotice">
						Your DiverObs will be used together with other data sources to aid
						in the management of your fishery.
					</p>
					<p className="beforeNotice">
						It is important to complete a survey soon after every dive.
					</p>
					<p className="beforeNotice">
						To proceed please scroll down and accept the Data Collection Notice
						below.
					</p>
					<div className="boldtext">
						Abalone Council Victoria Inc – Data Collection Notice
					</div>
					<p>
						The Abalone Council Victoria Inc
						ABN&nbsp;24&nbsp;981&nbsp;664&nbsp;394 (<b>ACV</b>) has developed
						and operates the “DiverObs” Application (<b>App</b>).
					</p>
					<p>
						Use of the App will require you to provide personal information,
						including your name, age and experience in abalone fishery, state
						government fisheries agency identification number, dive date,
						general location, observations relating to diving activities, and
						specifications of your mobile device.
					</p>
					<p>
						The primary purpose of collecting your personal information is to
						aid in fishery assessments. We may also use your personal
						information for secondary purposes including monitoring coverage,
						encouraging participation and engagement with the App, undertaking
						technical improvements to the App and complying with our regulatory
						and other legal obligations.
					</p>
					<p>
						While personal information will be held privately, it will be
						accessed by a database manager and contracted service providers. We
						will also provide your personal information to the Abalone Industry
						Organisation representing the fishery in which you operate, subject
						to them signing a Data Release Agreement. The Data Release Agreement
						will require the Abalone Industry Organisation to also hold your
						information privately but allow the release of anonymised reports to
						third parties to aid in fishery assessment. Your abalone industry
						organisation may link your DiverObs surveys to your GPS logger,
						catch and measuring data for verification and analysis to help
						understand your fishery. Abalone Industry Organisations will also be
						required to seek permission from you for any further use of the
						data. Your personal information will not be disclosed to overseas
						recipients.
					</p>
					<p>
						If personal information is not collected, we will be unable to
						accept data submitted by you through the App.
					</p>
					<p>
						More detailed information about how ACV manages your personal
						information is set out in our privacy policy, which can be found on
						our website at:{' '}
						<span className="link">www.abalonecouncilvictoria.com.au</span>
					</p>
					<p>
						Any requests or questions about how we deal with your personal
						information, including request for access or correction, should be
						sent to{' '}
						<span className="link">info@abalonecouncilvictoria.com.au</span>
					</p>
					<div className="boldtext">
						By clicking the accept button below, I confirm I have read and
						accept Data Collection Notice:
					</div>
					<Button
						variant="contained"
						color="primary"
						className="centered spacebefore"
						onClick={() => acceptPurpose(history)}>
						I Accept
					</Button>
				</>
			)}
		</Survey>
	);
};

export default connect(null, {
	acceptPurpose,
})(Purpose);
