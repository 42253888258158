import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import Button from '@material-ui/core/Button';

import {submitSurvey} from '../../redux/main';
import {Survey} from '../general/Styles';

const FinalStep = (props) => {
	const {survey, user_id, device_id, submitSurvey} = props;
	const {reef_code, reef_name, dive_date, species} = survey;
	console.log('species: ', species);
	const spec_observed = species
		.filter((spec) => spec.active)
		.map((spec) => spec.name)
		.join(', ');
	console.log('spec_observed: ', spec_observed);
	return (
		<Survey>
			<h3>Survey Summary</h3>
			<table className="finalSummary">
				<tbody>
					<tr>
						<th>Reef</th>
						<td>
							{reef_code} {reef_name}
						</td>
					</tr>
					<tr>
						<th>Dive Date</th>
						<td>{moment(dive_date).format('dddd D MMMM YYYY')}</td>
					</tr>
					<tr>
						<th>Species Observed</th>
						<td>{spec_observed}</td>
					</tr>
				</tbody>
			</table>

			<Button
				variant="contained"
				color="primary"
				className="centered spacebefore"
				onClick={() => submitSurvey(survey, user_id, device_id)}>
				Submit Survey
			</Button>
		</Survey>
	);
};
const mapStateToProps = (state) => {
	return {
		survey: state.main.survey,
		user_id: state.main.user.id,
		device_id: state.main.device_id,
	};
};

export default connect(mapStateToProps, {submitSurvey})(FinalStep);
