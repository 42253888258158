import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

export const Survey = styled(Container)`
	${({theme}) => `
		margin-top: 1em;
		margin-bottom: 6em;
		& h3 {
			color: ${theme.palette.secondary.main};
			font-weight: 100;
			font-size: 32px;
			margin: 0 -0.5em 0.25em;
			padding: 0.125em 0.5em;
		}
		& h4 {
			background-color: ${theme.palette.primary.main};
			color: white;
			margin: 0 -0.5em 1em;
			line-height: 1.5em;
			padding: 0.125em 0.675em;
			border-radius: 1em;
		}
		& legend.MuiFormLabel-root, 
		& .question {
			color: ${theme.palette.primary.main};
			font-weight: 700;
			margin-top: 1.5em;
			margin-bottom: 0.5em;
			line-height: 1.2;
		}
		& .question {
			margin-top: 0.5em;
		}	
		& legend.MuiFormLabel-root b, 
		& .question b{
			color: ${theme.palette.secondary.main};
		}
		& .question-sub {
			color: ${theme.palette.secondary.light};
			font-weight: 500;
		}
		& .question-head {
			margin-top: 1em;
			color: ${theme.palette.primary.main};
			font-weight: 900;
			margin-bottom: 1em;
			font-size: 22px;
			border-bottom: 1px solid ${theme.palette.primary.main};
		}
		& .DiveDate {
			margin-bottom: 1.5em;
		}
		& .DiveDate .question {
			margin-bottom: 0;
		}
		& .DiveDate .MuiFormControl-root {
			margin-top: 0.5em;
			margin-bottom: 0.5em;
		}
		& .MuiFormGroup-root,
		& .checkboxGroup {
			margin-left: 2em;
		}
		& .checkboxGroup label {
			display: block;
		}
		& fieldset.MuiFormControl-root {
			padding: 0 0.5em 1.5em;
		}
		& .allReefs fieldset.MuiFormControl-root {
			padding: 0 ;
		}
		& .allReefs .smu {
			position: relative;
		}
		& .allReefs .smu>span {
			display: block;
			width: 100%;
			text-align: right;
			position: sticky;
			top: 47px;
			background: white;
			z-index: 2;
			color:  ${theme.palette.secondary.main};
			font-weight: 500;
			font-size: 10px;
			border-top:  1px solid ${theme.palette.primary.main};
		}
		& .allReefs .my_reef .MuiTypography-root {
			background: ${theme.palette.secondary.opacity25};
			margin-left: -0.25em;
			padding-left: 0.25em;
			padding-right: 0.5em
		}
		& .allReefs.myReefs {
			margin-bottom: 2em;
		}
		& .allReefs p {
			color: ${theme.palette.primary.main};
			padding: 0 1em;
			font-size: 14px;
			font-style: italic;
		}
		& .allReefs p span {
			font-style: normal;
		}
		& .allReefs .inlineMenuIcon {
			vertical-align: text-top;
			margin: 0 0.25em;
		}
		& .MuiRadio-root,
		& .MuiCheckbox-root {
			padding: 5px;
		}
		& span.lip {
			padding: 0 5px 3px;
			margin-left: -5px;
			border-radius: 1em;
			font-weight: 500;
			white-space: nowrap;
		}
		& h4.black.lip {
			background: black;
			color: white;
		}
		& span.black.lip {
			padding: 0 2px 2px;
			margin-left: -2px;
			font-size: 0.9em;
			border-radius: 1em;
			background: rgba(0,0,0,0.2);
			color: black;
		}
		& .green.lip {
			background: #95D77540;
			color: #1C471C;
		}
		& h4.green.lip {
			background: #95D775;
		}		
		& .brown.lip {
			background: #D0957F40;
			color: #371A15;
		}
		& h4.brown.lip {
			background: #D0957F;
		}
		& .specifics b {
			text-transform: fuppercase;
		}
		& .boldtext {
			font-size: 20px;
			color: ${theme.palette.primary.main};
			font-weight: 900;
			margin-top: 1.5em;
			margin-bottom: 0.5em;
			line-height: 1.2;			
		}
		& .appTitle {
			text-align: center;
			padding: 4px 0;
			color: ${theme.palette.secondary.main};
			font-size: 50px;
			font-weight: 400;
				letter-spacing: -1px;
			line-height: 36px;
			& b {
				color: ${theme.palette.primary.main};
				font-weight: 900;
				letter-spacing: -2px;
			}
		}
		& .fullWidthLogo {
			max-width: 100%;
		}
		& .beforeNotice {
			font-style: italic;
			color: ${theme.palette.secondary.main};
		}
		& .MuiAlert-root {
			margin-top: 1em;
		}
		& .link {
			color: ${theme.palette.secondary.main};
			text-decoration: underline;
			margin: 0 0.4em;
		}
		& .nobreak {
			    white-space: nowrap;
		}

		& .finalSummary th {
			width: 5em;
			padding: 0.5em 1em 0.5em 1em;
			text-align: left;
		}

		@media (max-width: 320px) {
			.question-head {
				position: sticky;
				top: 30px;
				background: white;
				height: 2em;
				z-index: 1;
				margin-top: 0;
				padding-top: 1em;
				margin-bottom: 0.75em;
				padding-bottom: 0.25em;
				line-height: 1em;
				text-align: center;
			}
			.question-head.short {
				height: 1em;
				padding-top: 2em;
			}
			.nobreak {
				display: block;
			}
			&.speciesSpecific {
				padding-bottom: 10em;
			}
		}
	}
	`}
`;

export const AppHeader = styled(Paper)`
	${({theme}) => `
		height: 44px;
		position: sticky;
		top: 0;
		z-index: 5;

		& a {
			padding: 0;
			height: 41px;
			width: 36px;
			position: absolute;
			overflow: hidden;
			top: 2px;
			left: 4px;
		}
		& .optionsMenu {
			padding: 0;
			font-size: unset;
			position: absolute;
			top: 0;
			right: 0;
		}

		& div {
			text-align: center;
			padding: 4px 0;
			color: ${theme.palette.secondary.main};
			font-size: 36px;
			font-weight: 400;
				letter-spacing: -1px;
			line-height: 36px;
			& b {
				color: ${theme.palette.primary.main};
				font-weight: 900;
				letter-spacing: -2px;
			}
		}
	`}
`;

export const StepperWrapper = styled.div`
	${({theme}) => `
		max-width: 400px;
		flex-grow: 1;
	& header: {
		display: flex;
		align-items: center;
		height: 50;
		padding-left: ${theme.spacing(4)};
		background-color: ${theme.palette.background.default};
	},
	`}
`;

export const Policy = styled.div`
	${({theme}) => `
	& ol {
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;
	}
	& ol > li {
		// display: table;
		font-weight: bold;
		counter-increment: item;
		margin-bottom: 0.6em;
	}
	& ol > li:before {
		// display: table-cell;
		content: counters(item, ".") ". ";
		padding-right: 0.6em;
		font-weight: bold;
		font-size: 0.8em;
	}
	& li ol > li {
		font-weight: normal;
		// margin: 0;
	}
	& li ol > li:before {
		content: counters(item, ".") ".";
	}
	& li li {
		margin-left: 0.5em;
	}
	& li li li {
		margin-left: 1em;
	}
	& li li li li {
		margin-left: 1.5em;
	}
	& ol ol ol ol {
		counter-reset: letter;
	}
	& ol ol ol ol > li {
		counter-increment: letter;

	}
	& ol ol ol ol > li:before {
		content: counters(letter, "", lower-alpha) ") ";
	}
	& b {
		font-weight: bold;
	}
	& em {
		font-style: normal;
		text-decoration: underline;
	}	
	& .link {
		color: ${theme.palette.secondary.main};
		text-decoration: underline;
		margin: 0 0.4em;
	}
	`}
`;
export const HelpWrapper = styled.div`
	${({theme}) => `
		& h3 {
			color: ${theme.palette.secondary.main};
			font-weight: 100;
			font-size: 32px;
			margin: 0 -0.5em 0.25em;
			padding: 0.125em 0.5em;
		}
		& h4 {
			background-color: ${theme.palette.primary.main};
			color: white;
			margin: 0 -0.5em 1em;
			line-height: 1.0em;
			padding: 0.5em 0.675em;
			border-radius: 1em;
		}		
		& .contact {
			margin-left: 0.5em;
			margin-bottom: 0.5em;
		}
		& .contact .fishery {
			font-weight: bold;
		}
		& .contact .mobile,
		& .contact .name {
			margin-left: 1em;
		}
		& .contact .mobile span{
			vertical-align: top;
			margin-left: 0.25em;
		}
		& .or {
			margin-left: 2em;
			color: ${theme.palette.secondary.main};
		}
	`}
`;
export const InstallMsg = styled.div`
	${({theme}) => `
		& {
			position: fixed;
			bottom: 0;
			left: 2%;
			width: 96%;
			color: ${theme.palette.primary.main};
		}
		& em {
			color: ${theme.palette.secondary.main};
			display: block;
			line-height: 1.2;
			padding-top: 10px; 
		}
		& .icon {
			margin-bottom: 1em;
		}
		& .icon svg {
			float: left;
			margin-right: 5px;
		}
		& img {
			vertical-align: bottom;
			margin: 0 0.25em;
		}
		& .message {
			position: relative;
			border: 2px solid ${theme.palette.primary.main};
			background: white;
			padding: 1em;
			border-radius: 0.5em;
			font-size: 14px;
		}
		& .message span {
			font-weight: bold;
		}
		& .message svg {
			vertical-align: bottom;
		}
		& .arrow {
			width: 0; 
			height: 0; 
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid ${theme.palette.primary.main};
			margin: auto;
		}
		& li {
			margin-bottom: 0.75em;
		}
		& .skip {
			position: absolute;
			right: 6px;
			bottom: 3px;
			font-size: 12px;
			opacity: 0.75;
		}
	`}
`;
