import React from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import {AppHeader} from '../general/Styles';
import ScrollToTop from '../general/ScrollToTop';
import ServiceWorkerToast from '../general/ServiceWorkerToast';
import Stepper from '../survey/Stepper';
import OptionsMenu from './OptionsMenu';
import Home from './Home';
import Purpose from './Purpose';
import Settings from './Settings';
import imgLogo44 from '../../assets/logo44.png';

const AppRouter = (props) => {
	const {purpose_accepted, submitted} = props;
	return (
		<Router>
			<ScrollToTop />
			{purpose_accepted && (
				<AppHeader square>
					{submitted && (
						<Link to="/" className="logo">
							<img src={imgLogo44} alt="Logo" />
						</Link>
					)}
					<div>
						Diver<b>Obs</b>
					</div>
					{submitted && <OptionsMenu />}
				</AppHeader>
			)}
			<ServiceWorkerToast />
			<div>
				<Switch>
					<Route path="/purpose">
						<Purpose />
					</Route>
					<Route path="/survey">
						<Stepper />
					</Route>
					<Route path="/settings">
						<Settings />
					</Route>
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</div>
		</Router>
	);
};

const mapStateToProps = (state) => {
	return {
		purpose_accepted: state.main.purpose_accepted,
		submitted: state.main.user.submitted,
	};
};

export default connect(mapStateToProps)(AppRouter);
