import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {setSurveySpeciesValue} from '../../redux/main';

import ButtonBase from '@material-ui/core/ButtonBase';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AbaloneIcon from './AbaloneIcon';

const AbaRating = styled.div`
	${({theme}) => `
		margin: 0.5em 0em 3em;
        display: flex;
        flex-direction: row;
        flex: 1;
        & .MuiButtonBase-root {
            border: 3px solid transparent;

            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            & svg {
                margin: auto;
            }
            &>div  {
                margin-top: 0.25em;
                font-size: 0.8em;
                line-height: 0.9em;
                height: 1.8em;
                color: black;
            }
			&>div.didntDive {
				line-height: 11px;
				font-size: 10px;
				letter-spacing: -0.6px;
				font-weight: 700;
			}
			&>div.shorter {
				margin-top: 8px;
			}
		}
        & .chosen {
            font-weight: 700;
            border-color: inherit;
            border-radius: 1em;
        }
        & AbaloneIcon {
            font-size: 40px;
		}

    `}
`;
const lipColors = ['#000000', '#95D775', '#D0957F'];

const opacity = 0.26;
const labels = [
	{id: 0, size: 32, text: 'Not Sure'},
	{id: 1, size: 18, text: 'Very Poor'},
	{id: 2, size: 23, text: 'Poor'},
	{id: 3, size: 28, text: 'Fair'},
	{id: 4, size: 33, text: 'Good'},
	{id: 5, size: 38, text: 'Very Good'},
];

const Component = (props) => {
	const {spec_id, field, species, setSurveySpeciesValue} = props;
	const value = species[spec_id][field];

	return (
		<AbaRating>
			{labels.map((label) => (
				<ButtonBase
					key={label.id}
					className={label.id === value ? 'chosen' : ''}
					onClick={() => setSurveySpeciesValue(field, label.id, spec_id)}
					style={{color: lipColors[spec_id]}}>
					{label.id === 0 ? (
						<HighlightOffIcon
							style={{
								fontSize: label.size,
								opacity: label.id === value ? 1 : opacity,
							}}
							color="inherit"
						/>
					) : (
						<AbaloneIcon
							style={{
								fontSize: label.size,
								opacity: label.id === value ? 1 : opacity,
							}}
							color="inherit"
						/>
					)}
					<div>{label.text}</div>
				</ButtonBase>
			))}
		</AbaRating>
	);
};
const mapStateToProps = (state) => {
	return {
		species: state.main.survey.species,
	};
};

export default connect(mapStateToProps, {setSurveySpeciesValue})(Component);
