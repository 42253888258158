import React from 'react';
import {connect} from 'react-redux';
import {setSurveyReef, myReefSelector} from '../../redux/main';
import ReefList from '../form/ReefList';

const MyReefs = (props) => {
	const {reef_id, reefs, my_reefs, my_zones, setSurveyReef} = props;

	return (
		<div className="allReefs myReefs">
			{my_zones.length > 0 && (
				<div className="smu">
					<span>Recently Dived Reefs</span>
					{my_reefs.length === 0 ? (
						<p>
							Your ten most recently dived reefs will show here for easy access.
						</p>
					) : (
						<ReefList
							get={reef_id}
							set={(ev) => setSurveyReef(ev.target.value)}
							options={reefs}
							my_reefs={my_reefs}
						/>
					)}
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		reef_id: state.main.survey.id,
		my_reefs: state.main.user.my_reefs,
		my_zones: state.main.user.my_zones,
		reefs: myReefSelector(state),
	};
};

export default connect(mapStateToProps, {setSurveyReef})(MyReefs);
