// data exported @ 17:36:26 GMT+1100 (Australian Eastern Daylight Time) Mon Feb 22 2021

const zone_data = [
	{
		id: 1,
		state: 'WA',
		code: 'WA3',
		zone: 'Area 3',
		default_species: 1,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
			'yearsago_below',
			'yearsago_juvenile',
		],
		smus: [
			{
				smu: 'Augusta',
				reefs: [
					{
						id: 1,
						code: '1216',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 2,
						code: '1115',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 3,
						code: '1116',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 4,
						code: '1015',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 5,
						code: '0915',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 6,
						code: '0916',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 7,
						code: '0816',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 8,
						code: '0716',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 9,
						code: '0817',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 10,
						code: '0717',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 11,
						code: '0718',
						name: 'Augusta',
						species: ['1', '2'],
					},
					{
						id: 12,
						code: '0719',
						name: 'Augusta',
						species: ['1', '2'],
					},
				],
			},
			{
				smu: 'Windy',
				reefs: [
					{
						id: 13,
						code: '0720',
						name: 'Windy',
						species: ['1', '2'],
					},
					{
						id: 14,
						code: '0620',
						name: 'Windy',
						species: ['1'],
					},
					{
						id: 15,
						code: '0521',
						name: 'Windy',
						species: ['1', '2'],
					},
					{
						id: 16,
						code: '0421',
						name: 'Windy',
						species: ['1', '2'],
					},
					{
						id: 17,
						code: '0422',
						name: 'Windy',
						species: ['1', '2'],
					},
					{
						id: 18,
						code: '0322',
						name: 'Windy',
						species: ['1', '2'],
					},
					{
						id: 19,
						code: '0423',
						name: 'Windy',
						species: ['1', '2'],
					},
					{
						id: 20,
						code: '0424',
						name: 'Windy',
						species: ['1', '2'],
					},
					{
						id: 21,
						code: '0324',
						name: 'Windy',
						species: ['1', '2'],
					},
				],
			},
			{
				smu: 'Albany',
				reefs: [
					{
						id: 22,
						code: '0325',
						name: 'Albany',
						species: ['1'],
					},
					{
						id: 23,
						code: '0326',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 24,
						code: '0327',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 25,
						code: '0328',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 26,
						code: '0329',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 27,
						code: '0330',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 28,
						code: '0331',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 29,
						code: '0332',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 30,
						code: '0333',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 31,
						code: '0233',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 32,
						code: '0334',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 33,
						code: '0435',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 34,
						code: '0335',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 35,
						code: '0436',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 36,
						code: '0536',
						name: 'Albany',
						species: ['1'],
					},
					{
						id: 37,
						code: '0537',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 38,
						code: '0638',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 39,
						code: '0538',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 40,
						code: '0739',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 41,
						code: '0639',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 42,
						code: '0740',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 43,
						code: '0640',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 44,
						code: '0741',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 45,
						code: '0641',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 46,
						code: '0842',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 47,
						code: '0742',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 48,
						code: '0943',
						name: 'Albany',
						species: ['1', '2'],
					},
					{
						id: 49,
						code: '0843',
						name: 'Albany',
						species: ['1'],
					},
					{
						id: 50,
						code: '0743',
						name: 'Albany',
						species: ['1', '2'],
					},
				],
			},
			{
				smu: 'Hopetoun',
				reefs: [
					{
						id: 51,
						code: '1044',
						name: 'Hopetoun',
						species: ['1'],
					},
					{
						id: 52,
						code: '0944',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 53,
						code: '1045',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 54,
						code: '1046',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 55,
						code: '1047',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 56,
						code: '1048',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 57,
						code: '0948',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 58,
						code: '1049',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 59,
						code: '1050',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 60,
						code: '1051',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
					{
						id: 61,
						code: '1052',
						name: 'Hopetoun',
						species: ['1', '2'],
					},
				],
			},
		],
	},
	{
		id: 2,
		state: 'SA',
		code: 'SAWZB',
		zone: 'Western Zone',
		default_species: 1,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
			'yearsago_below',
			'yearsago_juvenile',
		],
		smus: [
			{
				smu: "D'Entrecasteaux Reef",
				reefs: [
					{
						id: 62,
						code: '1A',
						name: "D'Entrecasteaux Reef",
						species: ['0', '1'],
					},
					{
						id: 63,
						code: '1B',
						name: "D'Entrecasteaux Reef",
						species: ['0', '1'],
					},
					{
						id: 64,
						code: '1C',
						name: "D'Entrecasteaux Reef",
						species: ['0', '1'],
					},
					{
						id: 65,
						code: '1D',
						name: "D'Entrecasteaux Reef",
						species: ['0', '1'],
					},
					{
						id: 66,
						code: '1E',
						name: "D'Entrecasteaux Reef",
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Nuyts Archipelago',
				reefs: [
					{
						id: 67,
						code: '2A',
						name: 'Nth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 68,
						code: '2B',
						name: 'Nth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 69,
						code: '2C',
						name: 'Sth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 70,
						code: '2D',
						name: 'Sth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 71,
						code: '2E',
						name: 'Sth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 72,
						code: '2F',
						name: 'Nth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 73,
						code: '2G',
						name: 'Nth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 74,
						code: '2H',
						name: 'Nth Nuyts Archipelago',
						species: ['0', '1'],
					},
					{
						id: 75,
						code: '2J',
						name: 'Sth Nuyts Archipelago',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Cape Bauer',
				reefs: [
					{
						id: 76,
						code: '3A',
						name: 'Cape Bauer',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'North Nuyts',
				reefs: [
					{
						id: 77,
						code: '3B',
						name: 'North Nuyts',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Cape Bauer',
				reefs: [
					{
						id: 78,
						code: '3C',
						name: 'Cape Bauer',
						species: ['0', '1'],
					},
					{
						id: 79,
						code: '4A',
						name: 'Cape Bauer',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Pt Westall',
				reefs: [
					{
						id: 80,
						code: '4B',
						name: 'Pt Westall',
						species: ['0', '1'],
					},
					{
						id: 81,
						code: '4C',
						name: 'Pt Westall',
						species: ['0', '1'],
					},
					{
						id: 82,
						code: '4D',
						name: 'Pt Westall',
						species: ['0', '1'],
					},
					{
						id: 83,
						code: '4E',
						name: 'Pt Westall',
						species: ['0', '1'],
					},
					{
						id: 84,
						code: '4F',
						name: 'Pt Westall',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Searcy Bay',
				reefs: [
					{
						id: 85,
						code: '4G',
						name: 'Searcy Bay',
						species: ['0', '1'],
					},
					{
						id: 86,
						code: '4H',
						name: 'Searcy Bay',
						species: ['0', '1'],
					},
					{
						id: 87,
						code: '5A',
						name: 'Searcy Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Baird Bay',
				reefs: [
					{
						id: 88,
						code: '5B',
						name: 'Baird Bay',
						species: ['0', '1'],
					},
					{
						id: 89,
						code: '5C',
						name: 'Baird Bay',
						species: ['0', '1'],
					},
					{
						id: 90,
						code: '5D',
						name: 'Baird Bay',
						species: ['0', '1'],
					},
					{
						id: 91,
						code: '5E',
						name: 'Baird Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Venus Bay',
				reefs: [
					{
						id: 92,
						code: '5F',
						name: 'Venus Bay',
						species: ['0', '1'],
					},
					{
						id: 93,
						code: '6A',
						name: 'Venus Bay',
						species: ['0', '1'],
					},
					{
						id: 94,
						code: '6B',
						name: 'Venus Bay',
						species: ['0', '1'],
					},
					{
						id: 95,
						code: '6C',
						name: 'Venus Bay',
						species: ['0', '1'],
					},
					{
						id: 96,
						code: '6E',
						name: 'Venus Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Anxious Bay',
				reefs: [
					{
						id: 97,
						code: '6D',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 98,
						code: '7A',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 99,
						code: '7B',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 100,
						code: '8A',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 101,
						code: '8B',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 102,
						code: '8C',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 103,
						code: '8D',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 104,
						code: '8E',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
					{
						id: 105,
						code: '8Q',
						name: 'Anxious Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Elliston Cliffs',
				reefs: [
					{
						id: 106,
						code: '8F',
						name: 'Elliston Cliffs',
						species: ['0', '1'],
					},
					{
						id: 107,
						code: '8G',
						name: 'Elliston Cliffs',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Waterloo Bay',
				reefs: [
					{
						id: 108,
						code: '8H',
						name: 'Waterloo Bay',
						species: ['0', '1'],
					},
					{
						id: 109,
						code: '8J',
						name: 'Waterloo Bay',
						species: ['0', '1'],
					},
					{
						id: 110,
						code: '8K',
						name: 'Waterloo Bay',
						species: ['0', '1'],
					},
					{
						id: 111,
						code: '8L',
						name: 'Waterloo Bay',
						species: ['0', '1'],
					},
					{
						id: 112,
						code: '8M',
						name: 'Waterloo Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Elliston Cliffs',
				reefs: [
					{
						id: 113,
						code: '8N',
						name: 'Elliston Cliffs',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Flinders Island',
				reefs: [
					{
						id: 114,
						code: '8P',
						name: 'Flinders Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Ward Island',
				reefs: [
					{
						id: 115,
						code: '9A',
						name: 'Ward Island',
						species: ['0', '1'],
					},
					{
						id: 116,
						code: '9B',
						name: 'Ward Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Hotspot',
				reefs: [
					{
						id: 117,
						code: '9C',
						name: 'Hotspot',
						species: ['0', '1'],
					},
					{
						id: 118,
						code: '9D',
						name: 'Hotspot',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Flinders Island',
				reefs: [
					{
						id: 119,
						code: '9E',
						name: 'Flinders Island',
						species: ['0', '1'],
					},
					{
						id: 120,
						code: '9F',
						name: 'Flinders Island',
						species: ['0', '1'],
					},
					{
						id: 121,
						code: '9G',
						name: 'Flinders Island',
						species: ['0', '1'],
					},
					{
						id: 122,
						code: '9H',
						name: 'Flinders Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Sheringa',
				reefs: [
					{
						id: 123,
						code: '10A',
						name: 'Sheringa',
						species: ['0', '1'],
					},
					{
						id: 124,
						code: '10B',
						name: 'Sheringa',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Pearson Island',
				reefs: [
					{
						id: 125,
						code: '10C',
						name: 'Pearson Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Sheringa',
				reefs: [
					{
						id: 126,
						code: '11A',
						name: 'Sheringa',
						species: ['0', '1'],
					},
					{
						id: 127,
						code: '11B',
						name: 'Sheringa',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Drummond',
				reefs: [
					{
						id: 128,
						code: '11C',
						name: 'Drummond',
						species: ['0', '1'],
					},
					{
						id: 129,
						code: '12A',
						name: 'Drummond',
						species: ['0', '1'],
					},
					{
						id: 130,
						code: '12B',
						name: 'Drummond',
						species: ['0', '1'],
					},
					{
						id: 131,
						code: '12C',
						name: 'Drummond',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Coffin Bay',
				reefs: [
					{
						id: 132,
						code: '13A',
						name: 'Coffin Bay',
						species: ['0', '1'],
					},
					{
						id: 133,
						code: '13B',
						name: 'Coffin Bay',
						species: ['0', '1'],
					},
					{
						id: 134,
						code: '13C',
						name: 'Coffin Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Reef Head',
				reefs: [
					{
						id: 135,
						code: '13D',
						name: 'Reef Head',
						species: ['0', '1'],
					},
					{
						id: 136,
						code: '13E',
						name: 'Reef Head',
						species: ['0', '1'],
					},
					{
						id: 137,
						code: '13F',
						name: 'Reef Head',
						species: ['0', '1'],
					},
					{
						id: 138,
						code: '14A',
						name: 'Reef Head',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Avoid Bay',
				reefs: [
					{
						id: 139,
						code: '14B',
						name: 'Avoid Bay',
						species: ['0', '1'],
					},
					{
						id: 140,
						code: '14C',
						name: 'Avoid Bay',
						species: ['0', '1'],
					},
					{
						id: 141,
						code: '14D',
						name: 'Avoid Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Point Avoid',
				reefs: [
					{
						id: 142,
						code: '14E',
						name: 'Point Avoid',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Greenly Island',
				reefs: [
					{
						id: 143,
						code: '14F',
						name: 'Greenly Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Point Avoid',
				reefs: [
					{
						id: 144,
						code: '15A',
						name: 'Point Avoid',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Fishery Bay',
				reefs: [
					{
						id: 145,
						code: '15B',
						name: 'Fishery Bay',
						species: ['0', '1'],
					},
					{
						id: 146,
						code: '16A',
						name: 'Fishery Bay',
						species: ['0', '1'],
					},
					{
						id: 147,
						code: '16B',
						name: 'Fishery Bay',
						species: ['0', '1'],
					},
					{
						id: 148,
						code: '16C',
						name: 'Fishery Bay',
						species: ['0', '1'],
					},
					{
						id: 149,
						code: '17A',
						name: 'Fishery Bay',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Cape Catastrophe',
				reefs: [
					{
						id: 150,
						code: '17B',
						name: 'Cape Catastrophe',
						species: ['0', '1'],
					},
					{
						id: 151,
						code: '18A',
						name: 'Cape Catastrophe',
						species: ['0', '1'],
					},
					{
						id: 152,
						code: '18B',
						name: 'Cape Catastrophe',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Neptune Islands',
				reefs: [
					{
						id: 153,
						code: '17C',
						name: 'Neptune Islands',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Memory Cove',
				reefs: [
					{
						id: 154,
						code: '18C',
						name: 'Memory Cove',
						species: ['0', '1'],
					},
					{
						id: 155,
						code: '18E',
						name: 'Memory Cove',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Taylors Island',
				reefs: [
					{
						id: 156,
						code: '18D',
						name: 'Taylors Island',
						species: ['0', '1'],
					},
					{
						id: 157,
						code: '18K',
						name: 'Taylors Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'SW Thistle',
				reefs: [
					{
						id: 158,
						code: '18F',
						name: 'SW Thistle',
						species: ['0', '1'],
					},
					{
						id: 159,
						code: '18G',
						name: 'SW Thistle',
						species: ['0', '1'],
					},
					{
						id: 160,
						code: '18H',
						name: 'SW Thistle',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'NE Thistle',
				reefs: [
					{
						id: 161,
						code: '18J',
						name: 'NE Thistle',
						species: ['0', '1'],
					},
					{
						id: 162,
						code: '18L',
						name: 'NE Thistle',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Taylors Island',
				reefs: [
					{
						id: 163,
						code: '19A',
						name: 'Taylors Island',
						species: ['0', '1'],
					},
					{
						id: 164,
						code: '19B',
						name: 'Taylors Island',
						species: ['0', '1'],
					},
					{
						id: 165,
						code: '19C',
						name: 'Taylors Island',
						species: ['0', '1'],
					},
					{
						id: 166,
						code: '19D',
						name: 'Taylors Island',
						species: ['0', '1'],
					},
					{
						id: 167,
						code: '19E',
						name: 'Taylors Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Wedge Island',
				reefs: [
					{
						id: 168,
						code: '19F',
						name: 'Wedge Island',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Sir Joseph Banks',
				reefs: [
					{
						id: 169,
						code: '20A',
						name: 'Sir Joseph Banks',
						species: ['0', '1'],
					},
					{
						id: 170,
						code: '20B',
						name: 'Sir Joseph Banks',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Western SG',
				reefs: [
					{
						id: 171,
						code: '20C',
						name: 'Western SG',
						species: ['0', '1'],
					},
				],
			},
		],
	},
	{
		id: 3,
		state: 'SA',
		code: 'SACZ',
		zone: 'Central Zone',
		default_species: 1,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
			'yearsago_below',
			'yearsago_juvenile',
		],
		smus: [
			{
				smu: 'SA Central Zone',
				reefs: [
					{
						id: 172,
						code: '21A',
						name: 'Tiparra Reef',
						species: ['1'],
					},
					{
						id: 173,
						code: '21B',
						name: 'Tiparra Reef',
						species: ['1'],
					},
					{
						id: 174,
						code: '21C',
						name: 'Tiparra Reef',
						species: ['1'],
					},
					{
						id: 175,
						code: '21D',
						name: 'Tiparra Reef',
						species: ['1'],
					},
					{
						id: 176,
						code: '21E',
						name: 'Tiparra Reef',
						species: ['1'],
					},
					{
						id: 177,
						code: '21F',
						name: 'Tiparra Reef',
						species: ['1'],
					},
					{
						id: 178,
						code: '21G',
						name: 'Tiparra Reef',
						species: ['1'],
					},
					{
						id: 179,
						code: '21H',
						name: 'Cape Elizabeth',
						species: ['1'],
					},
					{
						id: 180,
						code: '21J',
						name: 'Western SG',
						species: ['1'],
					},
					{
						id: 181,
						code: '22A',
						name: 'West Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 182,
						code: '23A',
						name: 'West Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 183,
						code: '23B',
						name: 'West Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 184,
						code: '24A',
						name: 'West Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 185,
						code: '22B',
						name: 'East Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 186,
						code: '24B',
						name: 'East Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 187,
						code: '24C',
						name: 'East Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 188,
						code: '24D',
						name: 'East Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 189,
						code: '24E',
						name: 'East Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 190,
						code: '23C',
						name: 'South Yorke Peninsula',
						species: ['0', '1'],
					},
					{
						id: 191,
						code: '23D',
						name: 'South Yorke Peninsula',
						species: ['0', '1'],
					},
					{
						id: 192,
						code: '24F',
						name: 'South Yorke Peninsula',
						species: ['1'],
					},
					{
						id: 193,
						code: '25A',
						name: 'Fleurieu',
						species: ['0', '1'],
					},
					{
						id: 194,
						code: '25B',
						name: 'Fleurieu',
						species: ['0', '1'],
					},
					{
						id: 195,
						code: '25C',
						name: 'Fleurieu',
						species: ['0', '1'],
					},
					{
						id: 196,
						code: '25D',
						name: 'Fleurieu',
						species: ['0', '1'],
					},
					{
						id: 197,
						code: '26A',
						name: 'West KI',
						species: ['0', '1'],
					},
					{
						id: 198,
						code: '26B',
						name: 'West KI',
						species: ['0', '1'],
					},
					{
						id: 199,
						code: '27A',
						name: 'West KI',
						species: ['0', '1'],
					},
					{
						id: 200,
						code: '27B',
						name: 'West KI',
						species: ['0', '1'],
					},
					{
						id: 201,
						code: '28A',
						name: 'West KI',
						species: ['0', '1'],
					},
					{
						id: 202,
						code: '28B',
						name: 'West KI',
						species: ['0', '1'],
					},
					{
						id: 203,
						code: '29A',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 204,
						code: '29B',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 205,
						code: '29C',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 206,
						code: '29D',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 207,
						code: '30A',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 208,
						code: '30B',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 209,
						code: '30C',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 210,
						code: '31A',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 211,
						code: '31B',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 212,
						code: '31C',
						name: 'South KI',
						species: ['0', '1'],
					},
					{
						id: 213,
						code: '32A',
						name: 'North KI',
						species: ['0', '1'],
					},
					{
						id: 214,
						code: '32B',
						name: 'North KI',
						species: ['0', '1'],
					},
					{
						id: 215,
						code: '32C',
						name: 'North KI',
						species: ['0', '1'],
					},
				],
			},
		],
	},
	{
		id: 4,
		state: 'SA',
		code: 'SASZ',
		zone: 'Southern Zone',
		default_species: 0,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
			'yearsago_below',
			'yearsago_juvenile',
		],
		smus: [
			{
				smu: 'SA Southern Zone',
				reefs: [
					{
						id: 436,
						code: '1',
						name: 'Cape Jaffa',
						species: ['0', '1'],
					},
					{
						id: 437,
						code: '2',
						name: 'Nora Creina',
						species: ['0', '1'],
					},
					{
						id: 438,
						code: '3',
						name: 'Beachport',
						species: ['0', '1'],
					},
					{
						id: 439,
						code: '4',
						name: 'Rivoli Bay',
						species: ['0', '1'],
					},
					{
						id: 440,
						code: '5',
						name: 'South End',
						species: ['0'],
					},
					{
						id: 441,
						code: '6',
						name: 'Number 2 Rocks',
						species: ['0'],
					},
					{
						id: 442,
						code: '7',
						name: 'Admella',
						species: ['0'],
					},
					{
						id: 443,
						code: '8',
						name: 'Carpenters Rocks',
						species: ['0'],
					},
					{
						id: 444,
						code: '9',
						name: 'Gerloffs Bay',
						species: ['0', '1'],
					},
					{
						id: 445,
						code: '10',
						name: 'Blackfellows Caves',
						species: ['0'],
					},
					{
						id: 446,
						code: '11',
						name: 'Middle Point',
						species: ['0', '1'],
					},
					{
						id: 447,
						code: '12',
						name: 'Port Macdonnell',
						species: ['0', '1'],
					},
					{
						id: 448,
						code: '13',
						name: 'East Port Macdonnell',
						species: ['0'],
					},
				],
			},
		],
	},
	{
		id: 5,
		state: 'VIC',
		code: 'WZ',
		zone: 'Western Zone',
		default_species: 0,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
		],
		smus: [
			{
				smu: 'Portland',
				reefs: [
					{
						id: 251,
						code: '1.01',
						name: 'Discovery Bay',
						species: ['0'],
					},
					{
						id: 252,
						code: '1.02',
						name: 'Whites',
						species: ['0'],
					},
					{
						id: 253,
						code: '1.03',
						name: 'Water Springs',
						species: ['0'],
					},
					{
						id: 254,
						code: '1.04',
						name: 'Blowholes',
						species: ['0'],
					},
					{
						id: 255,
						code: '1.05',
						name: 'The Tits',
						species: ['0'],
					},
					{
						id: 256,
						code: '1.06',
						name: 'South Bridgewater',
						species: ['0'],
					},
					{
						id: 257,
						code: '1.07',
						name: 'Seal Caves',
						species: ['0'],
					},
					{
						id: 258,
						code: '1.08',
						name: 'Horeshoe',
						species: ['0'],
					},
					{
						id: 259,
						code: '2.01',
						name: 'Murrells',
						species: ['0'],
					},
					{
						id: 260,
						code: '2.02',
						name: 'Jones Bay',
						species: ['0'],
					},
					{
						id: 261,
						code: '2.03',
						name: 'Outside Nelson',
						species: ['0'],
					},
					{
						id: 262,
						code: '2.04',
						name: 'Devils Kitchen',
						species: ['0'],
					},
					{
						id: 263,
						code: '2.05',
						name: 'Inside Nelson',
						species: ['0'],
					},
					{
						id: 264,
						code: '2.06',
						name: 'Killer Waves',
						species: ['0'],
					},
					{
						id: 265,
						code: '2.07',
						name: 'Yellow Rock',
						species: ['0'],
					},
					{
						id: 266,
						code: '2.08',
						name: 'Cape Grant',
						species: ['0'],
					},
					{
						id: 267,
						code: '2.09',
						name: 'Passage',
						species: ['0'],
					},
					{
						id: 268,
						code: '2.10',
						name: 'Lawrence Rocks',
						species: ['0'],
					},
					{
						id: 269,
						code: '2.11',
						name: 'Blacknose',
						species: ['0', '1'],
					},
					{
						id: 270,
						code: '2.12',
						name: 'Hospital Reef',
						species: ['0', '1'],
					},
					{
						id: 271,
						code: '2.13',
						name: 'Dutton Way',
						species: ['0', '1'],
					},
					{
						id: 272,
						code: '2.14',
						name: 'Julia Bank',
						species: ['0', '1'],
					},
					{
						id: 273,
						code: '2.16',
						name: 'Minerva Reef',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Port Fairy',
				reefs: [
					{
						id: 274,
						code: '2.15',
						name: 'Yambuck',
						species: ['0'],
					},
					{
						id: 275,
						code: '3.05',
						name: 'The Crags',
						species: ['0'],
					},
					{
						id: 276,
						code: '3.06',
						name: "Burnet's",
						species: ['0'],
					},
					{
						id: 277,
						code: '3.07',
						name: 'Water Tower',
						species: ['0'],
					},
					{
						id: 278,
						code: '3.08',
						name: 'Lighthouse Reef',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Julia Percy',
				reefs: [
					{
						id: 279,
						code: '3.01',
						name: 'Julia Percy North',
						species: ['0'],
					},
					{
						id: 280,
						code: '3.02',
						name: 'JP - North East Reef',
						species: ['0'],
					},
					{
						id: 281,
						code: '3.03',
						name: 'JP - East Side',
						species: ['0'],
					},
					{
						id: 282,
						code: '3.04',
						name: 'JP - Prop Bay',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Warrnambool',
				reefs: [
					{
						id: 283,
						code: '3.09',
						name: 'Mills',
						species: ['0'],
					},
					{
						id: 284,
						code: '3.10',
						name: 'Killarney',
						species: ['0', '1'],
					},
					{
						id: 285,
						code: '3.11',
						name: 'Cutting',
						species: ['0', '1'],
					},
					{
						id: 286,
						code: '3.12',
						name: 'Thunder Point',
						species: ['0'],
					},
					{
						id: 287,
						code: '3.13',
						name: 'Lady Bay',
						species: ['0'],
					},
					{
						id: 288,
						code: '3.14',
						name: 'Levys Point',
						species: ['0'],
					},
				],
			},
		],
	},
	{
		id: 6,
		state: 'VIC',
		code: 'CZ',
		zone: 'Central Zone',
		default_species: 0,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
		],
		smus: [
			{
				smu: 'Shipwreck',
				reefs: [
					{
						id: 289,
						code: '4.01',
						name: 'Lake Gillear',
						species: ['0'],
					},
					{
						id: 290,
						code: '4.02',
						name: 'Antares',
						species: ['0'],
					},
					{
						id: 291,
						code: '5.01',
						name: 'Peterborough',
						species: ['0'],
					},
					{
						id: 292,
						code: '5.02',
						name: 'Point Hesse',
						species: ['0'],
					},
					{
						id: 293,
						code: '5.03',
						name: 'Port Campbell',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Otways',
				reefs: [
					{
						id: 294,
						code: '6.01',
						name: 'Moonlight Head',
						species: ['0'],
					},
					{
						id: 295,
						code: '6.02',
						name: 'Milanesia Beach',
						species: ['0'],
					},
					{
						id: 296,
						code: '6.03',
						name: 'Rotten Point',
						species: ['0'],
					},
					{
						id: 297,
						code: '7.03',
						name: 'East Otway',
						species: ['0'],
					},
					{
						id: 298,
						code: '7.04',
						name: 'Blanket Bay',
						species: ['0'],
					},
					{
						id: 299,
						code: '7.05',
						name: 'Apollo Bay',
						species: ['0'],
					},
					{
						id: 300,
						code: '7.06',
						name: 'Skenes Creek',
						species: ['0'],
					},
					{
						id: 301,
						code: '7.07',
						name: 'Franklin - Parker',
						species: ['0'],
					},
					{
						id: 302,
						code: '7.08',
						name: 'Parker River West Outside',
						species: ['0'],
					},
					{
						id: 303,
						code: '8.01',
						name: 'Kennett & Wye',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Surfcoast',
				reefs: [
					{
						id: 304,
						code: '8.02',
						name: 'Cumberland',
						species: ['0'],
					},
					{
						id: 305,
						code: '8.03',
						name: 'Eastern View',
						species: ['0'],
					},
					{
						id: 306,
						code: '9.01',
						name: 'Anglesea',
						species: ['0', '1'],
					},
					{
						id: 307,
						code: '9.02',
						name: 'Jan Juc',
						species: ['0', '1'],
					},
					{
						id: 308,
						code: '10.01',
						name: 'Point Impossible',
						species: ['0', '1'],
					},
					{
						id: 309,
						code: '10.02',
						name: 'Thirteenth Beach',
						species: ['0', '1'],
					},
					{
						id: 310,
						code: '10.03',
						name: 'Ocean Grove',
						species: ['0', '1'],
					},
					{
						id: 311,
						code: '10.04',
						name: 'Lonsdale Back Beach',
						species: ['0', '1'],
					},
					{
						id: 312,
						code: '10.05',
						name: 'The Anchor',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Port Phillip Bay',
				reefs: [
					{
						id: 313,
						code: '11.01',
						name: 'Indented Head',
						species: ['0'],
					},
					{
						id: 314,
						code: '11.02',
						name: 'Bellarine',
						species: ['0'],
					},
					{
						id: 315,
						code: '11.03',
						name: 'Point Wilson',
						species: ['0'],
					},
					{
						id: 316,
						code: '11.04',
						name: 'Kirks Point',
						species: ['0'],
					},
					{
						id: 317,
						code: '11.05',
						name: 'Werribee',
						species: ['0'],
					},
					{
						id: 318,
						code: '11.06',
						name: 'Sheok',
						species: ['0'],
					},
					{
						id: 319,
						code: '11.07',
						name: 'Point Cook',
						species: ['0'],
					},
					{
						id: 320,
						code: '11.08',
						name: 'Altona Bay',
						species: ['0'],
					},
					{
						id: 321,
						code: '11.09',
						name: 'Sticks Reef',
						species: ['0'],
					},
					{
						id: 322,
						code: '11.10',
						name: 'Williamstown',
						species: ['0'],
					},
					{
						id: 323,
						code: '11.11',
						name: 'St Kilda',
						species: ['0'],
					},
					{
						id: 324,
						code: '11.12',
						name: 'Brighton',
						species: ['0'],
					},
					{
						id: 325,
						code: '11.13',
						name: 'Black Rock',
						species: ['0'],
					},
					{
						id: 326,
						code: '11.14',
						name: 'Mordialloc',
						species: ['0'],
					},
					{
						id: 327,
						code: '11.15',
						name: 'Frankston',
						species: ['0'],
					},
					{
						id: 328,
						code: '11.16',
						name: 'Canadian Bay',
						species: ['0'],
					},
					{
						id: 329,
						code: '11.17',
						name: 'Mount Martha',
						species: ['0'],
					},
					{
						id: 330,
						code: '11.18',
						name: 'Portsea Bayside',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Back Beaches',
				reefs: [
					{
						id: 331,
						code: '12.01',
						name: 'Nepean Bay',
						species: ['0'],
					},
					{
						id: 332,
						code: '12.02',
						name: 'Portsea Ocean Beach',
						species: ['0'],
					},
					{
						id: 333,
						code: '12.03',
						name: 'Sorrento Ocean Beach',
						species: ['0'],
					},
					{
						id: 334,
						code: '12.04',
						name: 'Rye Ocean Beach',
						species: ['0'],
					},
					{
						id: 335,
						code: '12.05',
						name: 'Gunnamatta',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Flinders',
				reefs: [
					{
						id: 336,
						code: '12.06',
						name: 'Cape Schanck',
						species: ['0'],
					},
					{
						id: 337,
						code: '13.01',
						name: 'Bushrangers',
						species: ['0'],
					},
					{
						id: 338,
						code: '13.02',
						name: 'Symmonds West',
						species: ['0'],
					},
					{
						id: 339,
						code: '13.03',
						name: 'Symmonds East',
						species: ['0'],
					},
					{
						id: 340,
						code: '13.04',
						name: 'Cairns Bay',
						species: ['0'],
					},
					{
						id: 341,
						code: '13.05',
						name: 'Flinders',
						species: ['0', '1'],
					},
					{
						id: 342,
						code: '13.06',
						name: 'Westernport West',
						species: ['0', '1'],
					},
					{
						id: 343,
						code: '13.07',
						name: 'West Head',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Phillip Island',
				reefs: [
					{
						id: 344,
						code: '14.01',
						name: 'Ventor - Flynns',
						species: ['0', '1'],
					},
					{
						id: 345,
						code: '14.02',
						name: 'Cat Bay',
						species: ['0', '1'],
					},
					{
						id: 346,
						code: '14.03',
						name: 'Seal Rocks',
						species: ['0', '1'],
					},
					{
						id: 347,
						code: '14.04',
						name: 'Penguin Reserve',
						species: ['0', '1'],
					},
					{
						id: 348,
						code: '14.05',
						name: 'Summerlands - Berrys',
						species: ['0'],
					},
					{
						id: 349,
						code: '14.06',
						name: 'Pyramid Rock West',
						species: ['0'],
					},
					{
						id: 350,
						code: '14.07',
						name: 'Pyramid Rock East',
						species: ['0'],
					},
					{
						id: 351,
						code: '14.08',
						name: 'Sunderland Bay',
						species: ['0'],
					},
					{
						id: 352,
						code: '14.09',
						name: 'Cape Woolamai West',
						species: ['0'],
					},
					{
						id: 353,
						code: '14.10',
						name: 'Cape Woolami East',
						species: ['0'],
					},
					{
						id: 354,
						code: '14.11',
						name: 'Summerlands',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Kilkunda',
				reefs: [
					{
						id: 355,
						code: '15.01',
						name: 'San Remo',
						species: ['0', '1'],
					},
					{
						id: 356,
						code: '15.02',
						name: 'Powlett River',
						species: ['0'],
					},
					{
						id: 357,
						code: '15.03',
						name: 'Harmers Haven',
						species: ['0'],
					},
					{
						id: 358,
						code: '15.04',
						name: 'Cape Patterson',
						species: ['0'],
					},
					{
						id: 359,
						code: '15.05',
						name: 'Inverloch',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Liptrap',
				reefs: [
					{
						id: 360,
						code: '16.01',
						name: 'Venus Bay',
						species: ['0'],
					},
					{
						id: 361,
						code: '16.02',
						name: 'Morgans Beach',
						species: ['0'],
					},
					{
						id: 362,
						code: '16.03',
						name: 'Cape Liptrap West',
						species: ['0'],
					},
					{
						id: 363,
						code: '16.04',
						name: 'Cape Liptrap East',
						species: ['0'],
					},
					{
						id: 364,
						code: '16.05',
						name: 'Walkerville',
						species: ['0'],
					},
					{
						id: 365,
						code: '16.06',
						name: 'Waratah Reef',
						species: ['0', '1'],
					},
				],
			},
			{
				smu: 'Prom West',
				reefs: [
					{
						id: 366,
						code: '16.07',
						name: 'Cotters Beach',
						species: ['0'],
					},
					{
						id: 367,
						code: '17.01',
						name: 'Shellback Island',
						species: ['0'],
					},
					{
						id: 368,
						code: '17.02',
						name: 'Tongue Point',
						species: ['0'],
					},
					{
						id: 369,
						code: '17.03',
						name: 'Norman Island',
						species: ['0'],
					},
					{
						id: 370,
						code: '17.04',
						name: 'Leonard Point',
						species: ['0'],
					},
					{
						id: 371,
						code: '17.05',
						name: 'Pillar Point',
						species: ['0'],
					},
					{
						id: 372,
						code: '17.10',
						name: 'Great Glennie Island',
						species: ['0'],
					},
					{
						id: 373,
						code: '17.11',
						name: 'Dannevig Island',
						species: ['0'],
					},
					{
						id: 374,
						code: '17.12',
						name: 'Citadel Island',
						species: ['0'],
					},
					{
						id: 375,
						code: '17.13',
						name: 'McHugh Island',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Prom East',
				reefs: [
					{
						id: 376,
						code: '18.03',
						name: 'Refuge Cove',
						species: ['0'],
					},
					{
						id: 377,
						code: '18.04',
						name: 'Five Mile Beach',
						species: ['0'],
					},
					{
						id: 378,
						code: '18.05',
						name: 'Monkey Point',
						species: ['0'],
					},
					{
						id: 379,
						code: '18.06',
						name: 'Rabbit Island Rock',
						species: ['0'],
					},
					{
						id: 380,
						code: '18.07',
						name: 'Three Mile Beach',
						species: ['0'],
					},
					{
						id: 381,
						code: '18.09',
						name: 'Ninety Mile Beach',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Cliffys',
				reefs: [
					{
						id: 382,
						code: '18.08',
						name: 'Seal Island Group',
						species: ['0'],
					},
				],
			},
		],
	},
	{
		id: 7,
		state: 'VIC',
		code: 'EZ',
		zone: 'Eastern Zone',
		default_species: 0,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
		],
		smus: [
			{
				smu: 'Marlo',
				reefs: [
					{
						id: 383,
						code: '22.01',
						name: 'Marlo',
						species: ['0'],
					},
					{
						id: 384,
						code: '22.02',
						name: "French's",
						species: ['0'],
					},
					{
						id: 385,
						code: '22.03',
						name: 'Point Ricardo',
						species: ['0'],
					},
					{
						id: 386,
						code: '22.04',
						name: 'Cape Conran',
						species: ['0'],
					},
					{
						id: 387,
						code: '22.05',
						name: 'East Cape',
						species: ['0'],
					},
					{
						id: 388,
						code: '22.06',
						name: 'Yeerung Reef',
						species: ['0'],
					},
					{
						id: 389,
						code: '22.08',
						name: 'Pearl Point',
						species: ['0'],
					},
					{
						id: 390,
						code: '22.09',
						name: 'Tamboon Reef',
						species: ['0'],
					},
					{
						id: 391,
						code: '22.10',
						name: 'Clinton Rocks',
						species: ['0'],
					},
					{
						id: 392,
						code: '23.01',
						name: 'Point Hicks',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Mallacoota West',
				reefs: [
					{
						id: 393,
						code: '23.02',
						name: 'Whaleback',
						species: ['0'],
					},
					{
						id: 394,
						code: '23.03',
						name: 'Mueller',
						species: ['0'],
					},
					{
						id: 395,
						code: '23.04',
						name: 'Petrel Point',
						species: ['0'],
					},
					{
						id: 396,
						code: '23.05',
						name: 'Island Point',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Mallacoota Large',
				reefs: [
					{
						id: 397,
						code: '23.06',
						name: 'Big Rame',
						species: ['0'],
					},
					{
						id: 398,
						code: '24.00',
						name: 'The Skerries',
						species: ['0'],
					},
					{
						id: 399,
						code: '24.03',
						name: 'Easby Creek',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Mallacoota Central',
				reefs: [
					{
						id: 400,
						code: '24.04',
						name: 'Red River',
						species: ['0'],
					},
					{
						id: 401,
						code: '24.05',
						name: 'Secret Reef',
						species: ['0'],
					},
					{
						id: 402,
						code: '24.06',
						name: 'Sandpatch Point',
						species: ['0'],
					},
					{
						id: 403,
						code: '24.07',
						name: 'Sandpatch Point Lee',
						species: ['0'],
					},
					{
						id: 404,
						code: '24.08',
						name: 'Benedore',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Mallacoota Small',
				reefs: [
					{
						id: 405,
						code: '24.09',
						name: 'Little Rame',
						species: ['0'],
					},
					{
						id: 406,
						code: '24.14',
						name: 'Bastion Point',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Airport',
				reefs: [
					{
						id: 407,
						code: '25.10',
						name: 'Little Rame Lee',
						species: ['0'],
					},
					{
						id: 408,
						code: '25.11',
						name: 'Shipwreck - Seal Ck',
						species: ['0'],
					},
					{
						id: 409,
						code: '25.21',
						name: 'Quarry - Betka Beach',
						species: ['0'],
					},
					{
						id: 410,
						code: '25.15',
						name: 'Tullaberga Island',
						species: ['0'],
					},
					{
						id: 411,
						code: '25.16',
						name: 'Gabo Harbour',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Mallacoota East',
				reefs: [
					{
						id: 412,
						code: '24.17',
						name: 'Gabo Island',
						species: ['0'],
					},
					{
						id: 413,
						code: '24.18',
						name: 'Gunshot',
						species: ['0'],
					},
					{
						id: 414,
						code: '24.19',
						name: 'Iron Prince',
						species: ['0'],
					},
				],
			},
		],
	},
	{
		id: 8,
		state: 'NSW',
		code: 'NSWZ',
		zone: '',
		default_species: 0,
		questions: [
			'earlier_above',
			'last_above',
			'last_below',
			'last_juvenile',
			'yearsago_above',
		],
		smus: [
			{
				smu: 'Tweed to Tuross',
				reefs: [
					{
						id: 415,
						code: '1',
						name: 'Tweed to Port Stephans',
						species: ['0'],
					},
					{
						id: 416,
						code: '2',
						name: 'Port Stephens to Kiama',
						species: ['0'],
					},
					{
						id: 417,
						code: '3',
						name: 'Kiama to Ulladulla',
						species: ['0'],
					},
					{
						id: 418,
						code: '4',
						name: 'Ulladulla to South Brush',
						species: ['0'],
					},
					{
						id: 419,
						code: '5',
						name: 'South Brush to Batemans',
						species: ['0'],
					},
					{
						id: 420,
						code: '6',
						name: 'Batemans to Tuross',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Tuross to Eden',
				reefs: [
					{
						id: 421,
						code: '7',
						name: 'Tuross to Narooma',
						species: ['0'],
					},
					{
						id: 422,
						code: '8',
						name: 'Narooma to Bermagui',
						species: ['0'],
					},
					{
						id: 423,
						code: '9',
						name: 'Bermagui to Bunga',
						species: ['0'],
					},
					{
						id: 424,
						code: '10',
						name: 'Bunga to Moon Bay',
						species: ['0'],
					},
					{
						id: 425,
						code: '11',
						name: 'Moon Bay to Turingal',
						species: ['0'],
					},
					{
						id: 426,
						code: '12',
						name: 'Turingal to Long Beach',
						species: ['0'],
					},
					{
						id: 427,
						code: '13',
						name: 'Long Beach to Eden',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Eden to Wonboyn',
				reefs: [
					{
						id: 428,
						code: '14',
						name: 'Eden to Saltwater',
						species: ['0'],
					},
					{
						id: 429,
						code: '15',
						name: 'Saltwater to Bittangabee',
						species: ['0'],
					},
					{
						id: 430,
						code: '16',
						name: 'Bittangabee to Green Cape',
						species: ['0'],
					},
					{
						id: 431,
						code: '17',
						name: 'Green Cape to City Rock',
						species: ['0'],
					},
					{
						id: 432,
						code: '18',
						name: 'City Rock to Wonboyn',
						species: ['0'],
					},
				],
			},
			{
				smu: 'Wonboyn to Cape Howe',
				reefs: [
					{
						id: 433,
						code: '19',
						name: 'Wonboyn to Saltlake',
						species: ['0'],
					},
					{
						id: 434,
						code: '20',
						name: 'Saltlake',
						species: ['0'],
					},
					{
						id: 435,
						code: '21',
						name: 'Cape Howe',
						species: ['0'],
					},
				],
			},
		],
	},
];

export default zone_data;
