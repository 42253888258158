import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {abandonSurvey} from '../../redux/main';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SurveyStarted = (props) => {
	const {survey, abandonSurvey} = props;
	const history = useHistory();
	if (!survey) return null;
	const {dive_date, reef, reef_name} = survey;

	return (
		<div>
			<Dialog open={!!props.survey}>
				<DialogTitle>Survey Already Started</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You have already begun a survey for the{' '}
						<b>{moment(dive_date).format('DD/MM/YYYY')}</b>
						{reef && (
							<>
								{' '}
								at reef{' '}
								<b>
									{reef} {reef_name}
								</b>
							</>
						)}
						. What do you want to do?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={abandonSurvey}>Abandon</Button>
					<Button
						onClick={() => history.push({pathname: '/survey'})}
						color="primary"
						autoFocus>
						Resume
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		survey: state.main.survey,
	};
};

export default connect(mapStateToProps, {abandonSurvey})(SurveyStarted);
