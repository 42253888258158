import React from 'react';
import {connect} from 'react-redux';
import {setSurveyValue, setSurveySpecies} from '../../redux/main';

import {Survey} from '../general/Styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const PickSpecies = (props) => {
	const {reef_code, reef_name, species, setSurveySpecies} = props;

	return (
		<Survey>
			<h3>
				{reef_code} {reef_name}
			</h3>
			<form noValidate autoComplete="off">
				<FormControl component="fieldset">
					<FormLabel component="legend">
						Select (one or more) species to report on:
					</FormLabel>
					<div className="checkboxGroup">
						{species.map((option, i) => {
							return (
								<FormControlLabel
									control={
										<Checkbox
											checked={option.active}
											// checked={get.includes(option.id)}
											onChange={(ev) =>
												setSurveySpecies(ev.target.value, ev.target.checked)
											}
											name="observedSpecies"
											value={option.id}
											disabled={!option.available}
										/>
									}
									label={option.name}
									key={i}
								/>
							);
						})}
					</div>
				</FormControl>
			</form>
		</Survey>
	);
};
const mapStateToProps = (state) => {
	return {
		reef_code: state.main.survey.reef_code,
		reef_name: state.main.survey.reef_name,
		years: state.main.years,
		species: state.main.survey.species,
		lastDive: state.main.survey.lastDive,
	};
};

export default connect(mapStateToProps, {
	setSurveyValue,
	setSurveySpecies,
})(PickSpecies);
