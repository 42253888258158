import React from 'react';
import {connect} from 'react-redux';
import {setSurveyReef, reefSelector} from '../../redux/main';
import MenuIcon from '@material-ui/icons/Menu';
import ReefList from '../form/ReefList';

const AllReefs = (props) => {
	const {reef_id, reefs, my_reefs, setSurveyReef} = props;
	return (
		<div className="allReefs">
			{reefs.length === 0 ? (
				<p>
					You need to select one or more fisheries in the Settings section. You
					can access your <b>Settings</b> via the menu icon
					<MenuIcon fontSize="small" className="inlineMenuIcon" />
					in the top right-hand corner of the screen.
				</p>
			) : (
				<>
					{reefs.map((smu, i) => {
						return (
							<div className="smu" key={smu.smu + i}>
								<span>{smu.smu}</span>
								<ReefList
									get={reef_id}
									set={(ev) => setSurveyReef(ev.target.value)}
									options={smu.reefs}
									my_reefs={my_reefs}
								/>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		reef_id: state.main.survey.id,
		my_reefs: state.main.user.my_reefs,
		reefs: reefSelector(state),
	};
};

export default connect(mapStateToProps, {setSurveyReef})(AllReefs);
