import React from 'react';
import {connect} from 'react-redux';
import {setSurveyValue} from '../../redux/main';
import MomentUtils from '@date-io/moment';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

const DiveDate = (props) => {
	const {dive_date, setSurveyValue} = props;

	return (
		<div className="DiveDate">
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<div className="question">Date of Dive</div>
				<KeyboardDatePicker
					fullWidth
					margin="normal"
					id="date-picker-dialog"
					format="DD/MM/YYYY"
					value={dive_date}
					onChange={(date) => setSurveyValue('dive_date', date)}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>
			</MuiPickersUtilsProvider>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		dive_date: state.main.survey.dive_date,
	};
};

export default connect(mapStateToProps, {setSurveyValue})(DiveDate);
