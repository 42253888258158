import React from 'react';
import {connect} from 'react-redux';
import {setSurveySpeciesValue} from '../../redux/main';

import TextField from '@material-ui/core/TextField';

const TextArea = (props) => {
	const {spec_id, label, id, value, placeholder, setSurveySpeciesValue} = props;
	return (
		<>
			<div className="question-sub">{label}</div>
			<TextField
				fullWidth
				multiline
				variant="outlined"
				id={id}
				value={value}
				placeholder={placeholder}
				onChange={(ev) => setSurveySpeciesValue(id, ev.target.value, spec_id)}
			/>
		</>
	);
};
export default connect(null, {setSurveySpeciesValue})(TextArea);
