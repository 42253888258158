import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function ReefList(props) {
	const {get, set, options, my_reefs} = props;
	return (
		<FormControl component="fieldset">
			<RadioGroup aria-label="reefs" name="reefs" value={get} onChange={set}>
				{options.map((option, i) => {
					return (
						<div
							className={my_reefs.includes(option.id) ? 'my_reef' : ''}
							key={option.id}>
							<FormControlLabel
								value={option.id}
								control={<Radio />}
								label={option.code + ' ' + option.name}
								key={i}
							/>
						</div>
					);
				})}
			</RadioGroup>
		</FormControl>
	);
}
