import React from 'react';
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {acknowledgeUpdateMessage} from '../../redux/main';

const AppRouter = (props) => {
	const {updateMessage, acknowledgeUpdateMessage} = props;
	return (
		<Snackbar
			message={updateMessage}
			open={Boolean(updateMessage)}
			action={
				<>
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={() => acknowledgeUpdateMessage()}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</>
			}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		updateMessage: state.main.updateMessage,
	};
};

export default connect(mapStateToProps, {acknowledgeUpdateMessage})(AppRouter);
