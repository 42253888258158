import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as Icon} from './abaloneSmall.svg';

const AbaloneIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<Icon />
		</SvgIcon>
	);
};

export default AbaloneIcon;
