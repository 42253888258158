import React from 'react';
import {Policy} from '../general/Styles';

const Privacy = (props) => {
	return (
		<Policy>
			<h2>Abalone Council Victoria Inc – Privacy Policy</h2>
			<ol>
				<li>
					ABOUT US
					<ol>
						<li>
							Abalone Council Victoria Inc ABN 24 981 664 394 (<b>ACV</b>) is:
							<ol>
								<li>
									the peak body for the wild catch sector of the abalone
									industry in Victoria; and,
								</li>
								<li>
									has developed the DiverObs Application (<b>App</b>) to assist
									in collecting and analysing diver reported data of abalone
									fisheries in Victoria and other states, with the support of
									the Fisheries Research and Development Corporation (
									<b>FRDC</b>) and other Australian abalone industry bodies.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					PURPOSE OF THIS POLICY
					<ol>
						<li>
							This policy sets out how ACV will manage personal information
							collected by it in accordance with the Privacy Act 1988 (Cth) (
							<b>Act</b>).
						</li>
						<li>
							The Act currently defines “personal information” as meaning
							information or an opinion about an identified individual or an
							individual who is reasonably identifiable:
							<ol>
								<li>whether the information or opinion is true or not; and</li>
								<li>
									whether the information or opinion is recorded in a material
									form or not.
								</li>
							</ol>
						</li>
						<li>
							This privacy policy may be reviewed and updated by ACV from time
							to time. The most recent version of this policy is:
							<ol>
								<li>
									published on the ACV website:
									www.abalonecouncilvictoria.com.au; and,
								</li>
								<li>
									can be accessed via the link in settings on the DiverObs
									application (<b>App</b>).
								</li>
							</ol>
						</li>
						<li>
							If you would like a copy of this policy in another form, please
							contact ACV.
						</li>
					</ol>
				</li>
				<li>
					WHAT KIND OF PERSONAL INFOMRATION DOES ACV COLLECT AND HOLD?
					<ol>
						<li>
							<b>Collection of solicited information</b>
							<p>
								The ACV will only collect <em>non&nbsp;sensitive</em> personal
								information as defined by the Act. This data is collected for
								the <b>primary purposes</b> of:
							</p>
							<ol>
								<li>
									administering the association and its membership, through
									collection of your name, address and other contact
									information; and,
								</li>
								<li>
									aiding in fishery assessment, through collection of your name,
									age and experience in abalone fishery, state government
									fisheries agency identification number, dive date, general
									location and observations relating to diving activities, and
									specifications of your mobile device.
								</li>
							</ol>
						</li>
						<li>
							<b>
								Adoption use or disclosure of government related identifiers;
							</b>
							<p>
								The ACV is collecting fishery identification numbers issued by
								state government fisheries agencies to enable linking diver’s
								observations by industry to other data sources such as catch
								data. The ability to link diver observation data to other data
								sources improves interpretation and value in stock assessment
								and enables verification and comparison to observation data.
							</p>
						</li>
						<li>
							<b>Collection of unsolicited information</b>
							<p>
								If the ACV is provided with personal information which it has
								not requested or solicited; the ACV will comply with the
								requirements regarding dealing with unsolicited personal
								information set out in the Australian Privacy Principle 4.
							</p>
						</li>
						<li>
							<b>How does the ACV collect and hold personal information?</b>
							<ol>
								<li>
									The ACV will collect personal information from members via
									membership applications, and personal information from divers
									via the App. This information will be held in an electronic
									database hosted by Amazon Web Services within Australia.
								</li>
								<li>
									The ACV shall take reasonable steps to protect personal
									information that it holds from misuse, interference, loss as
									well as unauthorised access, modification, or disclosure. This
									includes appropriate measures to protect electronic materials
									and materials stored and generated in hard copy.
								</li>
								<li>
									When the ACV no longer needs or intends to use the information
									for any purpose for which it may be used or disclosed and,
									subject to any other legal or contractual requirements ACV is
									subject to, the information will be de-identified.
								</li>
							</ol>
						</li>

						<li>
							<b>
								What are the purposes for which the ACV collects, holds, uses,
								and discloses personal information?
							</b>
							<ol>
								<li>
									The ACV collects, holds, uses, and discloses this information
									for the <b>primary purpose</b> of aiding in fishery assessment
									and ACV administration. This may include data validation,
									cross referencing with other data sources, monitoring
									coverage, encouraging participation, and ongoing data
									analysis.
								</li>

								<li>
									ACV will enable access to your personal information by the
									following entities in the manner and for the purposes
									described below:
									<ol>
										<li>
											ACV’s officers, for the purpose of ensuring the
											association is properly managed;
										</li>
										<li>
											ACV’s appointed Database manager for the purpose of
											maintaining data integrity, conducting data analysis,
											providing reports and user support.
										</li>
										<li>
											ACV’s contracted service providers for the purpose of
											system development, data hosting and technical support.
										</li>
										<li>
											Abalone industry association(s) associated with fisheries
											in which you operate, for the purpose of aiding in fishery
											assessment. Summarised reports where divers remain
											anonymous may be released by the industry organisation to
											third parties.
										</li>
										<li>
											If an exception under Australian Privacy Policy Principal
											6 applies.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							How can you access personal information that the ACV holds?
							<p>
								You can access the personal information the ACV holds in
								relation to you at any time. If you wish to access this personal
								information, please contact us as set out below.
							</p>
							<p>
								Within 30 days of receipt of a request, ACV will either provide
								access to your personal information free of charge, or provide
								you with a written statement of its reasons for refusing the
								request.
							</p>
						</li>
						<li>
							<b>
								How can you correct personal information that the ACV holds?
							</b>
							<ol>
								<li>
									The ACV wishes to ensure that personal information that it
									collects, uses, or discloses is accurate, up-to-date, and
									complete.
								</li>
								<li>
									If you believe any personal information held by the ACV is
									inaccurate, out of date, incomplete, irrelevant, or
									misleading, please contact us with a request to update your
									personal information as set out below.
								</li>
								<li>
									Within 30 days of receipt of a request, the ACV will:
									<ol>
										<li>
											take reasonable steps to correct that information, if
											satisfied that the personal information it holds is
											inaccurate, out of date, incomplete or misleading; and,
										</li>
										<li>
											provide you with a written response setting out what
											corrections have been made and, if your requested
											corrections have not been made, its reasons for refusing
											to do so.
										</li>
									</ol>
								</li>

								<li>
									You can also edit your personal identification information at
									any time through the settings on the App.
								</li>
							</ol>
						</li>

						<li>
							<b>Can you deal with ACV anonymously?</b>
							<p>
								You are free to make general enquiries or to view the ACV
								website anonymously. However, to complete surveys using the App,
								your identity is needed to maintain data integrity and allow
								proper analysis. The use of pseudonyms when using the DiverObs
								application is not acceptable, and your responses will not be
								used.
							</p>
						</li>
						<li>
							<b>
								What does the ACV do to make sure that it complies with the
								Australian Privacy Principles?
							</b>
							<ol>
								<li>
									The ACV takes its privacy obligations seriously and is
									committed to meeting the highest standards when collecting,
									storing, using, and disclosing personal information.
								</li>
								<li>
									The ACV has taken reasonable steps to implement practice,
									procedures and systems relating to its functions and
									activities to ensure that it complies with this privacy
									policy, including.
									<ol>
										<li>
											Ensuring that the members of the FRDC Project no. 2019-038
											project team and steering committee are aware of the
											obligations under the Commonwealth Privacy Act, the ACV
											Privacy Policy, and the privacy obligations as a service
											provider to FRDC.
										</li>
										<li>
											Ensuring that this privacy policy is regularly reviewed
											and updated, as needed.
										</li>
									</ol>
								</li>
							</ol>
						</li>

						<li>
							<b>
								How can you make a complaint about how we have handled your
								personal information?
							</b>
							<ol>
								<li>
									You may make a complaint to the ACV if you consider that the
									ACV has interfered with your privacy or otherwise breached its
									obligations under the Australian Privacy Principles in
									relation to the management of your personal information.
								</li>
								<li>
									Any complaints should be in writing to the ACV at the contact
									details set out below.
								</li>
								<li>
									The ACV will take reasonable steps to investigate any
									complaint. We may need to contact you to seek further
									information to clarify your concerns.
									<p>
										If the ACV does not respond to the complaint within 30 days,
										or you are not satisfied with the outcome of our
										investigations, you can make a complaint directly to the
										Office of the Australian Information Commissioner. Further
										details about how to make a complaint to the OAIC are
										available at: <span className="link">www.oaic.gov.au</span>
									</p>
								</li>
							</ol>
						</li>

						<li>
							<b>How can you contact the ACV?</b>
							<p>The ACV can be contacted via any of the following methods:</p>
							<p>
								<b>E-mail:</b>
								<br />
								<span className="link">info@abalonecouncilvictoria.com.au</span>
							</p>
							<p>
								<b>Postal:</b>
								<br />
								Abalone Council Victoria Inc
								<br />
								PO Box 497
								<br />
								RYE VIC 3941
							</p>
						</li>
					</ol>
				</li>
			</ol>
		</Policy>
	);
};

export default Privacy;
