import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import Saga from './Saga';
import Reducer from './Reducer';

import {offline} from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';

const sagaMiddleware = createSagaMiddleware();


// Create data store
const Store = (persistCallback) => {

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const middleWare = compose(
		applyMiddleware(sagaMiddleware),
		offline({ ...offlineConfig, persistCallback: persistCallback }),
	);

	const theStore = createStore(Reducer, /* preloadedState, */ composeEnhancers(middleWare));
	sagaMiddleware.run(Saga);

	return theStore;
};

export default Store;
