import {takeEvery, put, select} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import moment from 'moment';
import zones from './zones';

const DIDNT_DIVE = 6;
const MY_REEFS_LENGTH = 10;
const LAST_YEAR = ['last_above', 'last_below', 'last_juvenile'];
const YEARS_AGO = ['yearsago_above', 'yearsago_below', 'yearsago_juvenile'];

// actions
const UPDATE_MESSAGE_ACKNOWLEDGED = 'UPDATE_MESSAGE_ACKNOWLEDGED';
const ACCEPT_PURPOSE = 'ACCEPT_PURPOSE';
const SET_PURPOSE_ACCEPTED = 'SET_PURPOSE_ACCEPTED';
const SET_STEP = 'SET_STEP';
const START_SURVEY = 'START_SURVEY';
const SET_STEP_DONE = 'SET_STEP_DONE';
const SET_SURVEY_VALUE = 'SET_SURVEY_VALUE';
const SET_SURVEY_SPECIES = 'SET_SURVEY_SPECIES';
const SET_SURVEY_SPECIES_VALUE = 'SET_SURVEY_SPECIES_VALUE';
const SET_SURVEY_FACTOR = 'SET_SURVEY_FACTOR';
const SET_SURVEY_REEF = 'SET_SURVEY_REEF';
const SUBMIT_SURVEY = 'SUBMIT_SURVEY';
const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
const SUBMIT_SURVEY_FAILED = 'SUBMIT_SURVEY_FAILED';
const ABANDON_SURVEY = 'ABANDON_SURVEY';

const SET_USER_VALUE = 'SET_USER_VALUE';
const SET_USER_STATE = 'SET_USER_STATE';
const SET_USER_STATE_ID = 'SET_USER_STATE_ID';
const SET_USER_ZONE = 'SET_USER_ZONE';
const SAVE_SETTINGS = 'SAVE_SETTINGS';
const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
const SAVE_USER_FAILED = 'SAVE_USER_FAILED';
const SET_USER_SUBMITTED = 'SET_USER_SUBMITTED';

// action creators
export const acknowledgeUpdateMessage = () => ({
	type: UPDATE_MESSAGE_ACKNOWLEDGED,
});
export const acceptPurpose = (history) => ({
	type: ACCEPT_PURPOSE,
	history: history,
});
export const setStep = (payload) => ({type: SET_STEP, payload});
export const startSurvey = (dive_date, history) => ({
	type: START_SURVEY,
	dive_date: moment(dive_date).format('YYYY-MM-DD'),
	history: history,
});
export const setSurveyValue = (name, value) => ({
	type: SET_SURVEY_VALUE,
	name: name,
	value: value,
});
export const setSurveyReef = (payload) => ({type: SET_SURVEY_REEF, payload});
export const setSurveySpecies = (name, checked) => ({
	type: SET_SURVEY_SPECIES,
	name: name,
	checked: checked,
});
export const setSurveySpeciesValue = (name, value, species) => ({
	type: SET_SURVEY_SPECIES_VALUE,
	name: name,
	value: value,
	species: species,
});
export const setSurveyFactor = (name, checked) => ({
	type: SET_SURVEY_FACTOR,
	name: name,
	checked: checked,
});
export const abandonSurvey = () => ({type: ABANDON_SURVEY});

export const setUserValue = (name, value) => ({
	type: SET_USER_VALUE,
	name: name,
	value: value,
});
export const setUserState = (name, checked) => ({
	type: SET_USER_STATE,
	name: name,
	checked: checked,
});
export const setUserStateID = (name, diver_id) => ({
	type: SET_USER_STATE_ID,
	name: name,
	diver_id: diver_id,
});
export const setUserZone = (name, checked) => ({
	type: SET_USER_ZONE,
	name: name,
	checked: checked,
});
export const saveSettings = (history, user, device_id) => ({
	type: SAVE_SETTINGS,
	payload: {
		history: history,
		user: user,
		device_id: device_id,
	},
	meta: {
		offline: {
			// the network action to execute:
			effect: {
				url: process.env.REACT_APP_SERVER_URL + 'diver',
				method: 'POST',
				json: {
					user: user,
					device_id: device_id,
				},
				mode: 'cors',
			},
			// action to dispatch when effect succeeds:
			commit: {type: SAVE_USER_SUCCESS},
			// action to dispatch if network action fails permanently:
			rollback: {type: SAVE_USER_FAILED},
		},
	},
});
export const submitSurvey = (survey, user_id, device_id) => ({
	type: SUBMIT_SURVEY,
	payload: {
		survey: survey,
		user_id: user_id,
		device_id: device_id,
	},
	meta: {
		offline: {
			// the network action to execute:
			effect: {
				url: process.env.REACT_APP_SERVER_URL + 'survey',
				method: 'POST',
				json: {
					survey: survey,
					diver_id: user_id,
					completed_at: moment().format('YYYY-MM-DD H:mm:ss'),
					device_id: device_id,
				},
				mode: 'cors',
			},
			// action to dispatch when effect succeeds:
			commit: {type: SUBMIT_SURVEY_SUCCESS},
			// action to dispatch if network action fails permanently:
			rollback: {type: SUBMIT_SURVEY_FAILED},
		},
	},
});

const initialSurvey = () => {
	return {
		dive_date: null,
		started_at: moment().format('YYYY-MM-DD H:mm:ss'),
		reef_id: null,
		reef_name: '',
		species: [
			{
				id: '0',
				tag: 'black',
				name: 'Blacklip',
				available: false,
				active: false,
				comment_habitat: '',
				comment_predators: '',
				comment_other: '',
				method: 0,
				demand: 0,
				measure: 0,
				equipment: 0,
				ability: 0,
				worked: 0,
				conditions: 0,
				habitat: 0,
				other: 0,
			},
			{
				id: '1',
				tag: 'green',
				name: 'Greenlip',
				available: false,
				active: false,
				comment_habitat: '',
				comment_predators: '',
				comment_other: '',
				method: 0,
				demand: 0,
				measure: 0,
				equipment: 0,
				ability: 0,
				worked: 0,
				conditions: 0,
				habitat: 0,
				other: 0,
			},
			{
				id: '2',
				tag: 'brown',
				name: 'Brownlip',
				available: false,
				active: false,
				comment_habitat: '',
				comment_predators: '',
				comment_other: '',
				method: 0,
				demand: 0,
				measure: 0,
				equipment: 0,
				ability: 0,
				worked: 0,
				conditions: 0,
				habitat: 0,
				other: 0,
			},
		],
	};
};

const initialState = {
	version: process.env.REACT_APP_VERSION,
	updateMessage: null,

	purpose_accepted: false,
	device_id: null,

	step: 0,
	maxSteps: 4,
	stepDone: false,

	survey: null,

	user: {
		id: null,
		first: '',
		family: '',
		age: '',
		commenced: '',
		states: [
			{state: 'WA', id_name: 'WA Diver #', active: false, id: ''},
			{state: 'SA', id_name: 'SA Diver #', active: false, id: ''},
			{state: 'VIC', id_name: 'Victorian PFN', active: false, id: ''},
			{state: 'NSW', id_name: 'NSW Diver #', active: false, id: ''},
		],
		submitted: false,
		my_zones: [],
		my_reefs: [],
	},

	past_surveys: [],
};

const Reducer = (state = initialState, action) => {
	console.log('REDUX action: ', action.type);
	switch (action.type) {
		case 'persist/REHYDRATE':
			const oldState = action.payload.main;
			if (!oldState) {
				return {
					...state,
				};
			}

			const version = process.env.REACT_APP_VERSION;
			if (oldState.version !== version) {
				// purge old state
				for (const key in oldState) {
					if (Object.hasOwnProperty.call(oldState, key)) {
						if (typeof state[key] === 'undefined') {
							delete oldState[key];
							console.warn(' - Deleted ' + key + ' from persisted state');
						}
					}
				}

				// apply new state to old
				for (const key in state) {
					if (Object.hasOwnProperty.call(state, key)) {
						if (typeof oldState[key] === 'undefined') {
							oldState[key] = state[key];
							console.warn(' + Added ' + key + ' to persisted state');
						}
					}
				}
				oldState.updateMessage =
					'DiverObs has been upgraded to Version: ' + version;
			}

			return {
				...oldState,
				version: version,
			};
		case UPDATE_MESSAGE_ACKNOWLEDGED:
			return {
				...state,
				updateMessage: null,
			};
		case SET_PURPOSE_ACCEPTED:
			return {
				...state,
				purpose_accepted: true,
				device_id: Date.now().toString().slice(-9),
			};
		case START_SURVEY:
			return {
				...state,
				survey: initialSurvey(),
			};
		case SET_STEP:
			return {
				...state,
				step: state.step + action.payload,
			};
		case SET_STEP_DONE:
			return {
				...state,
				stepDone: action.payload,
			};
		case SET_SURVEY_REEF:
			const my_reef = getReef(action.payload);
			const reefSpecies = state.survey.species.map((spec) => {
				spec.available = my_reef.species.includes(spec.id);
				spec.active =
					my_reef.species.length === 1 && my_reef.species[0] === spec.id;
				return spec;
			});

			return {
				...state,

				survey: {
					...state.survey,
					reef_id: action.payload,
					reef_code: my_reef.code,
					reef_name: my_reef.name,
					questions: my_reef.questions,
					species: reefSpecies,
				},
			};
		case SET_SURVEY_VALUE:
			return {
				...state,
				survey: {
					...state.survey,
					[action.name]: action.value,
				},
			};
		case SET_SURVEY_SPECIES:
			const species = [...state.survey.species];
			let index = Number(
				species.findIndex((spec) => Number(spec.id) === Number(action.name)),
			);
			species[index].active = action.checked;
			return {
				...state,
				maxSteps: Math.max(species.filter((spec) => spec.active).length + 3, 4),
				survey: {
					...state.survey,
					species: species,
				},
			};
		case SET_SURVEY_SPECIES_VALUE:
			const surveySpecies = [...state.survey.species];

			if (action.value === DIDNT_DIVE) {
				if (LAST_YEAR.includes(action.name)) {
					LAST_YEAR.forEach((question) => {
						if (state.survey.questions.includes(question)) {
							surveySpecies[action.species][question] = DIDNT_DIVE;
						}
					});
				}
				if (YEARS_AGO.includes(action.name)) {
					YEARS_AGO.forEach((question) => {
						if (state.survey.questions.includes(question)) {
							surveySpecies[action.species][question] = DIDNT_DIVE;
						}
					});
				}
			} else {
				if (LAST_YEAR.includes(action.name)) {
					LAST_YEAR.forEach((question) => {
						if (state.survey.questions.includes(question)) {
							if (surveySpecies[action.species][question] === DIDNT_DIVE) {
								delete surveySpecies[action.species][question];
							}
						}
					});
				}
				if (YEARS_AGO.includes(action.name)) {
					YEARS_AGO.forEach((question) => {
						if (state.survey.questions.includes(question)) {
							if (surveySpecies[action.species][question] === DIDNT_DIVE) {
								delete surveySpecies[action.species][question];
							}
						}
					});
				}
			}

			surveySpecies[action.species][action.name] = action.value;
			return {
				...state,
				survey: {
					...state.survey,
					species: surveySpecies,
				},
			};
		case SET_SURVEY_FACTOR:
			const surveyFactors = [...state.survey.factors];
			if (action.checked && !surveyFactors.includes(action.name)) {
				surveyFactors.push(action.name);
			}
			if (!action.checked && surveyFactors.includes(action.name)) {
				surveyFactors.splice(surveyFactors.indexOf(action.name), 1);
			}
			return {
				...state,
				survey: {
					...state.survey,
					factors: surveyFactors,
				},
			};
		case SUBMIT_SURVEY:
			let updated_reefs = [...state.user.my_reefs];
			const reef_id = Number(state.survey.reef_id);
			updated_reefs = updated_reefs.filter((id) => id !== reef_id);
			updated_reefs.push(reef_id);
			if (updated_reefs.length > MY_REEFS_LENGTH) {
				updated_reefs = updated_reefs.slice(-MY_REEFS_LENGTH);
			}

			return {
				...state,
				step: 0,
				past_surveys: [...state.past_surveys, state.survey.dive_date],
				survey: null,
				user: {
					...state.user,
					my_reefs: updated_reefs,
				},
			};
		case ABANDON_SURVEY:
			return {
				...state,
				step: 0,
				survey: null,
			};
		/**
		 * SET USER SETTINGS
		 */
		case SET_USER_VALUE:
			return {
				...state,
				user: {
					...state.user,
					[action.name]: action.value,
				},
			};
		case SET_USER_STATE:
			// update the list of states
			const states = state.user.states;
			for (let i = 0; i < states.length; i++) {
				if (states[i].state !== action.name) continue;
				states[i].active = action.checked;
			}

			// remove zones from my_zones list if the state is removed
			const activeStates = [];
			for (let i = 0; i < states.length; i++) {
				if (states[i].active) activeStates.push(states[i].state);
			}
			const activeZones = [];
			for (let j = 0; j < zones.length; j++) {
				const zone = zones[j];
				if (activeStates.includes(zone.state)) {
					activeZones.push(zone.code);
				}
			}
			const currentZones = [...state.user.my_zones];
			const newZones = currentZones.filter((zone) =>
				activeZones.includes(zone),
			);

			return {
				...state,
				user: {
					...state.user,
					states: states,
					my_zones: newZones,
				},
			};
		case SET_USER_STATE_ID:
			const statesID = state.user.states;
			for (let i = 0; i < statesID.length; i++) {
				if (statesID[i].state !== action.name) continue;
				statesID[i].id = action.diver_id;
			}
			return {
				...state,
				user: {
					...state.user,
					states: statesID,
				},
			};
		case SET_USER_ZONE:
			const userZones = [...state.user.my_zones];
			if (action.checked && !userZones.includes(action.name)) {
				userZones.push(action.name);
			}
			if (!action.checked && userZones.includes(action.name)) {
				userZones.splice(userZones.indexOf(action.name), 1);
			}
			return {
				...state,
				user: {
					...state.user,
					my_zones: userZones,
				},
			};
		case SET_USER_SUBMITTED:
			return {
				...state,
				user: {
					...state.user,
					submitted: true,
				},
			};
		case SAVE_USER_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					id: action.payload.diver_id,
				},
			};
		default:
			return state;
	}
};

const mainSelector = (state) => state.main;
const _acceptPurpose = function* (action) {
	yield put({type: SET_PURPOSE_ACCEPTED});
	action.history.push({pathname: '/settings'});
};
const _startSurvey = function* (action) {
	yield put({
		type: SET_SURVEY_VALUE,
		name: 'dive_date',
		value: action.dive_date,
	});
	action.history.push({pathname: '/survey'});
};
const _saveSettings = function* (action) {
	yield put({type: SET_USER_SUBMITTED});
	action.payload.history.push({pathname: '/'});
};
const _setMyReef = function* (action) {
	const {survey} = yield select(mainSelector);
	const countSpecies = survey.species
		.map((spec) => (spec.available ? 1 : 0))
		.reduce((a, c) => a + c, 0);
	if (countSpecies === 1) {
		yield put({type: SET_STEP, payload: 2});
	} else {
		yield put({type: SET_STEP, payload: 1});
	}
};
const _isStepDone = function* (action) {
	const {step, maxSteps, survey} = yield select(mainSelector);
	let isDone = false;
	if (maxSteps - step === 1) {
		// This is the final step, always say Not Done, so no NEXT button
	} else {
		switch (step) {
			case 0:
				isDone = survey.reef_id !== null;
				break;
			case 1:
				isDone = survey.species.filter((spec) => spec.active).length > 0;
				break;
			case 2:
			case 3:
			case 4:
				const active = survey.species.filter((spec) => spec.active);
				const spec = active[step - 2];
				const questionsDone = survey.questions.every(
					(quest) => typeof spec[quest] === 'number',
				);
				const catchDone = typeof spec.catch === 'number';
				const opinionDone = typeof spec.opinion === 'number';
				isDone = questionsDone && catchDone && opinionDone;
				break;
			default:
				break;
		}
	}
	yield put({type: SET_STEP_DONE, payload: isDone});
};

// saga listens for start and stop actions
export const mainSaga = function* () {
	yield takeEvery(ACCEPT_PURPOSE, _acceptPurpose);
	yield takeEvery(START_SURVEY, _startSurvey);
	yield takeEvery(SET_STEP, _isStepDone);
	yield takeEvery(SET_SURVEY_VALUE, _isStepDone);
	yield takeEvery(SET_SURVEY_SPECIES, _isStepDone);
	yield takeEvery(SET_SURVEY_SPECIES_VALUE, _isStepDone);

	yield takeEvery(SET_SURVEY_REEF, _setMyReef);
	yield takeEvery(SAVE_SETTINGS, _saveSettings);
};

// selector to
export const reefSelector = createSelector(mainSelector, (main) => {
	const value = [];
	zones.forEach((zone) => {
		if (main.user.my_zones.includes(zone.code)) {
			value.push(...zone.smus);
		}
	});
	return value;
});
export const myReefSelector = createSelector(mainSelector, (main) => {
	const value = [];
	zones.forEach((zone) => {
		if (main.user.my_zones.includes(zone.code)) {
			zone.smus.forEach((smu) => {
				smu.reefs.forEach((reef) => {
					if (main.user.my_reefs.includes(reef.id)) {
						value.push(reef);
					}
				});
			});
		}
	});
	return value;
});

const getReef = (reef_id) => {
	reef_id = Number(reef_id);

	let theReef = false;
	for (let z = 0; z < zones.length; z++) {
		const zone = zones[z];
		for (let s = 0; s < zone.smus.length; s++) {
			const smu = zone.smus[s];
			for (let r = 0; r < smu.reefs.length; r++) {
				if (smu.reefs[r].id === reef_id) {
					theReef = smu.reefs[r];
					break;
				}
			}
			if (theReef) break;
		}
		if (theReef) {
			theReef.questions = zone.questions;
			break;
		}
	}
	return theReef;
};
export default Reducer;
