import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Store from './redux/Store';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theStore = Store(() => {
	ReactDOM.render(
		<React.StrictMode>
			<App store={theStore} />
		</React.StrictMode>,
		document.getElementById('root'),
	);
});

serviceWorker.register({
	onUpdate: (registration) => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event) => {
				if (event.target.state === 'activated') {
					window.location.reload();
				}
			});
			waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
		}
	},
});
