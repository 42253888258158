import React from 'react';
import {connect} from 'react-redux';
import {setUserZone} from '../../redux/main';
import zones from '../../redux/zones';
import Checkbox from '@material-ui/core/Checkbox';

const PickFisheries = (props) => {
	const {user, setUserZone} = props;
	const my_states = user.states
		.filter((state) => state.active)
		.map((state) => state.state);

	if (my_states.length === 0) return null;

	return (
		<>
			<div className="question">Which fisheries do you dive?</div>
			<div className="checkblox PickFisheries">
				{zones.map((zone, i) => {
					if (!my_states.includes(zone.state)) return null;
					return (
						<div key={zone.code}>
							<Checkbox
								checked={user.my_zones.includes(zone.code)}
								onChange={(ev) =>
									setUserZone(ev.target.name, ev.target.checked)
								}
								name={zone.code}
								id={zone.code}
							/>
							<label htmlFor={zone.code}>
								{zone.state} {zone.zone}
							</label>
						</div>
					);
				})}
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		user: state.main.user,
	};
};

export default connect(mapStateToProps, {setUserZone})(PickFisheries);
